export enum SkillLevelEnum {
    Beginner = 1,
    Intermediate = 2,
    Advanced = 3,
}

export enum SocialNetworkEnum {
    TWITTER = 'twitter',
    DISCORD = 'discord',
    TELEGRAM = 'telegram',
}

export enum NotificationModalTypeEnum {
    RegistrationRewardReceived = 'RegistrationRewardReceivedNotification',
    HeartRecovered = 'HeartRecoveredNotification',
    BybitDealRewardClaimed = 'BybitDealRewardClaimedNotification',
    BybitDeal = 'BybitDealNotification'
}

export enum NftCertificateStatusEnum {
    INITIALIZED = 'initialized',
    SUCCESS = 'success',
    FAILED = 'failed',
    PROCESSING = 'processing',
}

export enum AchievementTabEnum {
    Badges = 'badges',
    NftCertificates = 'nft-certificates',
}

export enum HeartEnum {
    EMPTY = 0,
    FULL = 5,
}

export enum ExamSlugs {
    ROOKIE = 'web3-exam-rookie',
    STARTER = 'web3-exam-starter',
    EXPLORER = 'web3-exam-explorer',
    EVANGELIST = 'web3-exam-evangelist',
}

export enum QuestPathTypeEnum {
    LINEAR = 1,
    UNRESTRICTED = 2,
}

export enum Modals {
    AUTH = 'showAuth',
    WALLET_CONNECT = 'showWalletConnect',
    LEAVING = 'showLeaving',
    FEEDBACK = 'showFeedback',
    DEACTIVATE_ACCOUNT = 'showDeactivateAccount',
    PASSWORD_CHANGE = 'showPasswordChange',
    ADD_PASSWORD = 'showAddPassword',
    EMAIL_VERIFY = 'showEmailVerify',
    FORCE_EMAIL_VERIFICATION = 'showForceEmailVerification',
    STARTED_ROUND = 'showStartedRound',
    UNAVAILABLE_ROUND = 'showUnavailableRound',
    LUCKY_DRAW_WINNERS = 'showLuckyDrawWinners',
    LUCKY_DRAW_RESTART = 'showLuckyDrawRestart',
    REGISTER_BONUS = 'showRegisterBonus',
    INTRODUCTION = 'showIntroduction',
    HEART_RECOVERY = 'showHeartRecovery',
    EXAM_RETRY = 'showExamRetry',
    NFT_MINT_SUCCESS = 'showNftMintSuccess',
    BADGE = 'showBadge',
    NFT_CERTIFICATE = 'showNftCertificate',
    METAMASK_AUTH = 'showMetamaskAuthModal',
    METAMASK_AUTH_INTRO = 'showMetamaskAuthIntroModal',
    COINBASE_AUTH = 'showCoinbaseAuthModal',
    COINBASE_AUTH_INTRO = 'showCoinbaseAuthIntroModal',
    CUSTOM_ERROR = 'showCustomErrorModal',
    CONNECTED_SOCIAL_ACCOUNT_MODAL = 'showConnectedSocialAccountModal',
    USER_SOCIAL_ACCOUNTS_MODAL = 'showUserSocialAccountsModal',
    SOCIAL_ACCOUNT_ERROR_MODAL = 'showSocialAccountErrorModal',
    GUILD_UNFOLLOW_MODAL = 'showGuildUnfollowModal',
    REPORT_MODAL = 'showReportModal',
    FILTERS_MODAL = 'showFiltersModal',
    GUILDS_SUGGESTIONS_MODAL = 'showGuildsSuggestionsModal',
    BYBIT_DEAL_REWARD_CLAIMED_MODAL = 'showBybitDealRewardClaimed',
    BYBIT_DEAL_MODAL = 'showBybitDeal',
    UNLOCK_STUDIO_STEP_MODAL = 'showUnlockStudioStep',
    UNLOCKED_STUDIO_MODAL = 'showUnlockedStudio',
    VIDEO_MODAL = 'showVideo',
    INVITE_FRIENDS = 'showInviteFriends',
    BROWSER_NOTIFICATIONS = 'showBrowserNotificationsModal',
    SEASON_WINNERS = 'showSeasonWinnersModal',
    TANGIBLE_REWARD_CLAIM = 'showTangibleRewardClaim',
    EXTENSION_CONNECTED_MODAL = 'showExtensionConnectedModal',
}

export enum SettingsQueryParamsEnum {
    ACTION = 'action',
}

export enum SettingsActionEnum {
    WALLET = 'wallet',
    EMAIL_VERIFICATION = 'email-verification',
    MOBILE_APP_CONNECT = 'mobile-app-connect',
}

export enum MissionsQueryParamsEnum {
    SECTION = 'section',
}

export enum MissionsSectionNameEnum {
    WEB3_MISSIONS_SECTION = 'web3-missions',
}

export enum MissionQueryParamsEnum {
    SECTION = 'section',
}

export enum MissionSectionNameEnum {
    PRIZE_POOL = 'prize-pool',
}

export enum CompanionFooterRaisedEnum {
    IS_MISSION_ACTIVE = 'isMissionActive',
    IS_SELECTED_MISSION_FOOTER_SHOWN = 'isSelectedMissionFooterShown',
    IS_MISSIONS_FOOTER_SHOWN = 'isMissionsFooterShown',
}

export enum VerificationStatusEnum {
    NOT_VERIFIED = 'not_verified',
    VERIFIED = 'verified',
}

export enum ReportReasonEnum {
    SCAM = 'scam',
    MISINFORMATION = 'misinformation',
    TECHNICAL_ISSUE = 'technical_issue',
    PHISHING = 'phishing',
    COPYRIGHT = 'copyright',
    ABUSIVE = 'abusive',
    OTHER = 'other',
}

export enum RewardEnum {
    ALL = 'all',
    REWARD = 'reward',
}

export enum FilterTypeEnum {
    GUILDS = 'Guilds',
    MISSIONS = 'Missions',
    GUILD_MISSIONS = 'GuildMissions',
}

export enum MissionProgressEnum {
    ALL = 'all',
    STARTED_BY_USER = 'started_by_user',
    COMPLETED_BY_USER = 'completed_by_user',
}

export enum MissionsRewardTypeEnum {
    CRYPTO_CURRENCY = 'crypto_currency',
    OTHER = 'other',
    ALL = 'all',
    FIAT = 'fiat',
}

export enum RestrictionTypeEnum {
    RESTRICT = 'restrict',
    ALLOW = 'allow',
}
