import { defineStore } from 'pinia';
import {
    Quest,
    RoundData,
    RoundStateActions,
    ISocialAccount,
    WalletAddressData,
} from '@/js/quests/game/types/types';
import { QuestTaskStatusEnum } from '@/js/quests/game/types/enums';
import { getValueFromLocalStorage } from '@/js/quests/game/helpers/localStorageHelper';
import { LOCAL_STORAGE_KEYS } from '@/js/quests/game/constants/localStorageKeys';
import { useQuizHelperStore } from './QuestHelperStore';
import { pushUserIdToDataLayer } from '@/js/quests/global/helpers/pushUserIdToDataLayer';
import { updateUserWallet } from '../../../web3/services/api';
import { updateUserSocialAccount } from '@/js/quests/dashboard/services/api';
import { getUserSocials } from '@/js/quests/game/services/api';
import { SocialNetworkEnum } from '@/js/quests/dashboard/types/enums';

interface QuestRoundAndUserData {
    userProfile: {
        introductionCompletedAt: string | null;
        isHeartsEnabled: boolean | null;
        userHearts: number | null;
        soundsEnabled: boolean | null
        degree: {
            current_degree: null,
            amount_for_next_degree: null,
            current_degree_amount: null,
        },
        bits: number | null;
        userAvatarHash?: string;
        isWalletConnected: boolean | null,
        isTwitterConnected: boolean | null,
        wallets: WalletAddressData[] | null,
        isPrimaryNewWallet: boolean;
        selectedWallet: WalletAddressData | null;
        selectedSocialAccount: ISocialAccount | null;
        socialAccounts: ISocialAccount[] | null,
    },
    roundData: {
        isExam: boolean | null;
        missionSlug: string | null,
        guildSlug: string | null,
        roundCount: number | null;
        roundType: number | null;
        timeGiven: number | null;
        contentUrl: string | null,
        isPreview: boolean | false,
        roundStateActions: RoundStateActions | null;
        originalQuestions: Quest[];
        questions: Quest[];
        totalPossibleReward: number | null;
        questionsStatus: QuestTaskStatusEnum[];
        allQuestionsStatus: QuestTaskStatusEnum[];
        incorrectAnswers: Quest[];
        currentQuestions: Quest[];
        integerDigitCount?: number | null;
        tutorial?: {
            url: string,
        } | null
        aiContent: string | null;
    },
    currentQuestionIndex: number,
    correctStreakCount: number,
    incorrectStreakCount: number,
    showSearchBar: boolean,
}

export const useQuestRoundDataStore = defineStore({
    id: 'questRoundData',
    state: (): QuestRoundAndUserData => ({
        userProfile: {
            introductionCompletedAt: null,
            isHeartsEnabled: null,
            userHearts: null,
            soundsEnabled: null,
            degree: {
                current_degree: null,
                amount_for_next_degree: null,
                current_degree_amount: null,
            },
            bits: null,
            userAvatarHash: null,
            isWalletConnected: null,
            isTwitterConnected: null,
            wallets: null,
            isPrimaryNewWallet: false,
            selectedWallet: null,
            selectedSocialAccount: null,
            socialAccounts: null,
        },
        roundData: {
            isExam: null,
            missionSlug: null,
            guildSlug: null,
            roundCount: null,
            roundType: null,
            timeGiven: null,
            roundStateActions: null,
            contentUrl: null,
            isPreview: false,
            originalQuestions: [],
            questions: [],
            totalPossibleReward: null,
            questionsStatus: [],
            allQuestionsStatus: [],
            incorrectAnswers: [],
            currentQuestions: [],
            integerDigitCount: null as number | null,
            tutorial: null,
            aiContent: null,
        },
        currentQuestionIndex: 0,
        correctStreakCount: 0,
        incorrectStreakCount: 0,
        showSearchBar: true,
    }),
    getters: {
        currentQuest(): Quest {
            return this.roundData.currentQuestions[this.currentQuestionIndex];
        },
        currentQuestionPosition(): number {
            const questions = this.roundData.originalQuestions;
            const currentQuestionUuid = this.roundData.currentQuestions[this.currentQuestionIndex]?.uuid;
            const index = questions.findIndex((question: Quest) => question.uuid === currentQuestionUuid);

            return index >= 0 ? index + 1 : 1;
        },
    },
    actions: {
        setUserAndQuestRoundData(data: RoundData) {
            this.setRoundData(data);

            this.setUserProfileData(data);

            this.setUserBits(data.user.quest_account?.balance?.amount || '');

            this.prepareQuestionsForRound();
        },
        setRoundData(data: RoundData) {
            this.roundData.isExam = data.quest_round.quest_mission?.is_exam;
            this.roundData.roundCount = data.quest_round.order;
            this.roundData.roundType = data.quest_round.type;
            this.roundData.timeGiven = data.user_quest_round.remaining_time;
            this.roundData.roundStateActions = data.user_quest_round.state?.actions || null;
            this.roundData.isPreview = data.user_quest_round.is_preview;
            this.roundData.originalQuestions = [...data.quest_actions];
            this.roundData.questions = [...data.quest_actions];
            this.roundData.totalPossibleReward = Number(data.quest_round.reward.amount);
            this.roundData.contentUrl = data?.content_url || null;
            this.roundData.tutorial = data.quest_round?.tutorial || null;
            this.roundData.missionSlug = data.quest_round?.quest_mission?.slug || null;
            this.roundData.guildSlug = data.quest_round?.quest_mission?.quest_guild?.slug || null;
            this.roundData.aiContent = data.ai_content || null;
        },
        setUserProfileData(data: RoundData) {
            this.userProfile.soundsEnabled = data.user?.quest_profile?.settings.is_sounds_enabled || null;
            this.userProfile.introductionCompletedAt = data.user?.quest_profile?.introduction_completed_at || null;
            this.userProfile.isHeartsEnabled = data.user?.quest_profile?.settings?.is_heart_enabled || null;
            this.userProfile.userHearts = data.user?.quest_profile?.hearts;
            this.userProfile.userAvatarHash = data.user?.quest_profile?.avatar_hash;
            this.userProfile.degree = { ...this.userProfile.degree, ...data.user?.quest_profile?.degree };
            this.userProfile.isWalletConnected = data.user?.quest_profile?.is_wallet_connected;
            this.userProfile.isTwitterConnected = data.user?.quest_profile?.is_twitter_connected;
            this.userProfile.wallets = data.user?.quest_profile?.wallets?.addresses;
            this.userProfile.socialAccounts = data.user?.quest_profile?.social_accounts;

            pushUserIdToDataLayer(data.user?.quest_profile?.id);
        },
        setUserBits(accountBalance: string): void {
            this.userProfile.bits = Number(accountBalance);
        },
        prepareQuestionsForRound(): void {
            const quizHelperStore = useQuizHelperStore();

            this.checkAllQuestionsStatus();

            this.filterIncompleteQuestions();

            this.findIncorrectQuestionsAndAddToIncorrectAnswers();

            this.setStatusOfCurrentQuestionsToUnanswered();

            this.duplicateCurrentQuestionsArray();

            this.restoreCurrentQuestionIndexFromLocalStorage();

            this.restoreMotivationStrikeCountFromLocalStorage();

            quizHelperStore.populateSortQuestionOptions();
        },
        checkAllQuestionsStatus(): void {
            this.roundData.allQuestionsStatus = this.roundData.questions.map((question) => {
                const { uuid } = question;
                if (this.roundData.roundStateActions && this.roundData.roundStateActions[uuid]) {
                    if (this.roundData.roundStateActions[uuid]?.is_completed === true) {
                        return QuestTaskStatusEnum.CORRECT;
                    }

                    if (this.roundData.roundStateActions[uuid]?.is_completed === false) {
                        return QuestTaskStatusEnum.INCORRECT;
                    }
                }

                return QuestTaskStatusEnum.UNANSWERED;
            });
        },
        filterIncompleteQuestions(): void {
            this.roundData.questions = this.roundData.questions.filter((question) => {
                const { uuid } = question;

                return !(this.roundData.roundStateActions
                    && this.roundData.roundStateActions[uuid]
                    && this.roundData.roundStateActions[uuid]?.is_completed);
            });
        },
        findIncorrectQuestionsAndAddToIncorrectAnswers(): void {
            // eslint-disable-next-line no-restricted-syntax
            for (const uuid in this.roundData.roundStateActions) {
                if (this.roundData.roundStateActions[uuid]?.is_completed === false) {
                    const incorrectQuestion = this.roundData.questions.find((question) => question.uuid === uuid);
                    if (incorrectQuestion
                        && !this.roundData.incorrectAnswers.some((incAnswer) => incAnswer.uuid === incorrectQuestion.uuid)) {
                        this.roundData.incorrectAnswers.push(incorrectQuestion);
                    }
                }
            }
        },
        setStatusOfCurrentQuestionsToUnanswered(): void {
            this.roundData.questionsStatus = new Array(this.roundData.questions.length).fill(QuestTaskStatusEnum.UNANSWERED);
        },
        restoreCurrentQuestionIndexFromLocalStorage(): void {
            const savedCurrentQuestUuid = getValueFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_ACTION);
            const foundIndex = this.roundData.currentQuestions.findIndex((question) => question.uuid === savedCurrentQuestUuid);
            if (foundIndex >= 0) {
                this.currentQuestionIndex = foundIndex;
            }
        },
        restoreMotivationStrikeCountFromLocalStorage(): void {
            const savedCorrectStreakCount = getValueFromLocalStorage(LOCAL_STORAGE_KEYS.CORRECT_STREAK_COUNT);
            const savedIncorrectCorrectStreakCount = getValueFromLocalStorage(LOCAL_STORAGE_KEYS.INCORRECT_STREAK_COUNT);

            if (savedCorrectStreakCount) {
                this.correctStreakCount = Number(savedCorrectStreakCount);
            }

            if (savedIncorrectCorrectStreakCount) {
                this.incorrectStreakCount = Number(savedIncorrectCorrectStreakCount);
            }
        },
        duplicateCurrentQuestionsArray(): void {
            this.roundData.currentQuestions = [...this.roundData.questions];
        },
        async setUserWalletPrimary(wallet: WalletAddressData) {
            try {
                wallet.is_primary = true;
                const { data: { wallets } } = await updateUserWallet(wallet);

                if (wallets) {
                    this.userProfile.wallets = wallets;
                }
            } catch (error) {
                wallet.is_primary = false;
            }
        },
        async setUserSocialAccountPrimary(socialAccount: ISocialAccount) {
            try {
                socialAccount.is_primary = true;
                const { data: { social_accounts } } = await updateUserSocialAccount(socialAccount);

                if (socialAccount) {
                    this.userProfile.socialAccounts = social_accounts.filter((account) => account.social_network === socialAccount.social_network);
                }
            } catch (error) {
                socialAccount.is_primary = false;
            }
        },
        async fetchUserSocials(socialNetwork: SocialNetworkEnum) {
            const { data: { social_accounts } } = await getUserSocials();

            this.userProfile.socialAccounts = social_accounts.filter((account) => account.social_network === socialNetwork);
        },
    },
});
