const prefix = '/api/quests';

export const APIEndpoints = {
    VERIFY_ROUND_TASK_SUBMISSION: `${prefix}/user-actions/complete`,
    CREATE_INITIAL_USER_ROUND: `${prefix}/user-rounds/`,
    VISIT_SITE_COMPLETED: 'quests/user-actions/resource/visit',
    GET_USER_SOCIALS_ACCOUNTS: 'user/socials',
    GET_SUGGESTED_MISSIONS: (missionSlug: string) => `${prefix}/missions/${missionSlug}/suggestions`,
    GET_PRE_ROUND_DATA: (roundUuid: string) => `${prefix}/rounds/${roundUuid}`,
    START_ROUND_AND_GET_DATA: (userRoundUuid: string) => `${prefix}/user-rounds/${userRoundUuid}/start`,
    CANCEL_ROUND_BY_USER_CHOICE: (userRoundUuid: string) => `${prefix}/user-rounds/${userRoundUuid}/cancel`,
    CANCEL_ROUND_BY_TIMEOUT: (userRoundUuid: string) => `${prefix}/user-rounds/${userRoundUuid}/fail`,
    SET_INTRO_AS_COMPLETED: (userRoundUuid: string) => `${prefix}/user-rounds/${userRoundUuid}/introduction/complete`,
    SET_TASK_AS_COMPLETED: (id: string) => `${prefix}/custom-tasks/${id}/complete`,
};
