import { dashboardApiEndpoints } from '@/js/quests/dashboard/constants/dashboardApiEndpoints';
import {
    StudioStatsResponse,
    UnlockStudioStepsStatusResponse,
} from '@/js/quests/dashboard/pages/guilds-studio/types/studio';
import useAxiosRepository from '@/js/repositories/BaseRepository';

const axiosRepo = useAxiosRepository();

export const getGuildsStudioStats = async (showError = true): Promise<StudioStatsResponse> => {
    const api = dashboardApiEndpoints.GET_GUILDS_STUDIO_STATS;

    return axiosRepo.get(api, { showError });
};

export const getUnlockStudioStepsStatus = async (showError = true): Promise<UnlockStudioStepsStatusResponse> => {
    const api = dashboardApiEndpoints.GET_UNLOCK_STUDIO_STEPS_STATUS;

    return axiosRepo.get(api, { showError });
};

export const setUnlockStudioRole = async (showError = true): Promise<void> => {
    const api = dashboardApiEndpoints.SET_UNLOCK_STUDIO_ROLE;

    return axiosRepo.post(api, null, {
        showError,
    });
};
