import { getTrans } from '@/js/helpers/getTrans';

interface Messages {
    MISSION_COMPLETION: string;
    TOO_MANY_ATTEMPTS: string;
    GOT_IT: string;
    FALLBACK_MESSAGE: string;
}

export const messages: Messages = {
    MISSION_COMPLETION: getTrans('quests-game.missionHasBeenCompleted', 'This Mission has been completed, closed or the time ran out. Please start this Mission again to continue.'),
    TOO_MANY_ATTEMPTS: getTrans('quests-game.tooManyAttempts', 'Hold your horses, cowboy - too many attempts!'),
    GOT_IT: getTrans('quests-game.gotIt', 'Got it'),
    FALLBACK_MESSAGE: getTrans('quests-game.unexpectedError', 'Unexpected error occurred.'),
};
