interface QuestsRoutes {
    MISSION_ROUTE: string;
    MISSIONS_ROUTE: string;
    GUILDS_ROUTE: string;
    NFT_MINT_ROUTE: string;
}

export const questsRoutes: QuestsRoutes = {
    MISSION_ROUTE: '/mission',
    MISSIONS_ROUTE: '/missions',
    GUILDS_ROUTE: '/guilds',
    NFT_MINT_ROUTE: '/wallet?tab=nft-certificates',
};
