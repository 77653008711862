import { defineStore } from 'pinia';
import config from '../../../../quests/config';
import { handleHttpError } from '@/js/quests/game/services/httpResponsesHandler';
import { useQuestStore } from '../QuestStore';
import {
    cancelRoundByTimeout,
    cancelRoundByUserChoice,
    createInitialUserRound,
    getPreRoundData,
    setIntroAsCompleted,
    startRoundAndGetData,
    verifyRoundTaskSubmission,
    cancelRoundWithBeacon,
    getSuggestedMissions,
} from '@/js/quests/game/services/api';

import {
    removeQuestValuesFromLocalStorage, setValueToLocalStorage,
} from '@/js/quests/game/helpers/localStorageHelper';

import {
    AsyncApiStore,
    DegreeChangedEvent,
    HeartLostEvent,
    MissionCompleteEvent,
    PreRoundData, QuestTaskValidationData,
    RoundCompleteEvent,
    RoundStrikeEvent,
    StreakIncrementedEvent,
    StreakRewardCalculationEvent,
    SponsorInfo, ExamCompletedEvent,
    AchievementsUnlockedEvent,
    Quest,
    StreakIncrementRewardedEvent,
    CustomTaskSuggestionEvent,
} from '@/js/quests/game/types/types';
import { closeCurtains } from '@/js/quests/game/animations/closeCurtains';
import {
    QuestActionValidationResultEnum,
    QuestEventTypeEnum,
    QuestNotificationTypesEnum,
    QuestRoundTypeEnum,
    QuestTaskStatusEnum,
} from '@/js/quests/game/types/enums';

import { useNotificationStore } from './NotificationStore';
import { useQuestRoundDataStore } from './QuestRoundDataStore';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { LOCAL_STORAGE_KEYS } from '@/js/quests/game/constants/localStorageKeys';
import { toggleSoundSetting } from '@/js/quests/dashboard/services/api';
import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';

export const useAsyncApiStore = defineStore({
    id: 'asyncApiStore',
    state: (): AsyncApiStore => ({
        isLoading: false,
        isRoundInitialized: false,
        isLoadingPreRoundData: null,
        isRoundStopped: false,
        preRoundData: {
            type: null,
            title: '',
            subtitle: '',
            description: [''],
            order: null,
            image: {
                url: '',
            },
            total_round_count: null,
            quest_sponsors: null,
            quest_mission: null,
            content: null,
            uuid: null,
            quest_round_ai_content_id: null,
        },
    }),
    getters: {
        roundSponsorInfo(): SponsorInfo | null {
            return this.preRoundData?.quest_sponsors?.[0] || null;
        },
    },
    actions: {
        sendRoundCancelBeacon(): void {
            const questStore = useQuestStore();

            cancelRoundWithBeacon(questStore.userRoundUuid);
        },
        terminateRound(exit = false) {
            removeQuestValuesFromLocalStorage();
            const { roundData: { missionSlug, guildSlug, isPreview } } = useQuestRoundDataStore();

            const missionRoutePath = isPreview
                ? dashboardRoutesPaths.MISSION_PREVIEW_ROUTE_PATH
                : dashboardRoutesPaths.MISSION_ROUTE_PATH;

            const redirectSlug: string = missionSlug && guildSlug
                ? missionRoutePath.replace(':guildSlug', guildSlug).replace(':slug', missionSlug)
                : questsRoutes.MISSIONS_ROUTE;

            if (exit) {
                closeCurtains(redirectSlug);
            }
        },
        terminateRoundDueToTechnicalIssue() {
            if (!config.terminateRoundAfterFail) {
                return;
            }

            this.isRoundInitialized = false;
            this.isRoundStopped = true;
            this.cancelRoundByChoice();
        },
        async advanceToNextRound() {
            const questStore = useQuestStore();

            const uuid = questStore.hasTimeEnded ? questStore.roundUuid : questStore.nextRoundUuid;

            if (!uuid) {
                closeCurtains(questsRoutes.MISSIONS_ROUTE);

                return;
            }

            try {
                const response = await createInitialUserRound(uuid);
                this.isRoundInitialized = true;

                if (response.redirect_url) {
                    closeCurtains(response.redirect_url);
                }

                if (response.round_uuid && response.user_round_uuid) {
                    closeCurtains(questsRoutes.MISSION_ROUTE);
                }
            } catch (error) {
                handleHttpError(error);

                throw error;
            }
        },
        async fetchPreRoundData() {
            const questStore = useQuestStore();

            this.isLoadingPreRoundData = true;
            try {
                const response = await getPreRoundData(questStore.roundUuid);
                this.setPreRoundData(response);
            } catch (error) {
                this.setPreRoundData();
                handleHttpError(error);

                throw error;
            } finally {
                this.isLoadingPreRoundData = false;
                removeQuestValuesFromLocalStorage();
            }
        },
        async initializeMissionRound() {
            const questRoundDataStore = useQuestRoundDataStore();
            const questStore = useQuestStore();

            this.isLoading = true;
            try {
                const response = await startRoundAndGetData(questStore.userRoundUuid);
                questRoundDataStore.setUserAndQuestRoundData(response);
                this.isRoundInitialized = true;
            } catch (error) {
                this.isRoundInitialized = false;
                handleHttpError(error);

                throw error;
            } finally {
                this.isLoading = false;
            }
        },
        async finishIntroSteps() {
            const questStore = useQuestStore();

            questStore.showIntro = false;
            questStore.introStep = 0;
            questStore.nextQuestionCountdown();

            await setIntroAsCompleted(questStore.userRoundUuid);
        },
        async cancelRoundByChoice(exit = false) {
            const questStore = useQuestStore();

            if (questStore.roundIsCompleted) {
                this.terminateRound(true);

                return;
            }

            try {
                await cancelRoundByUserChoice(questStore.userRoundUuid);
            } catch (error) {
                handleHttpError(error);
            } finally {
                this.terminateRound(exit);
            }
        },
        async cancelRoundByTimeEnd() {
            const questStore = useQuestStore();

            await cancelRoundByTimeout(questStore.userRoundUuid);
        },
        async handleInGameSoundsSetting() {
            const questRoundDataStore = useQuestRoundDataStore();

            const isSoundsEnabled = questRoundDataStore.userProfile.soundsEnabled;

            try {
                await toggleSoundSetting(isSoundsEnabled);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to update settings:', error);
                this.setError(error.response.data.errors);
            } finally {
                questRoundDataStore.userProfile.soundsEnabled = !isSoundsEnabled;
            }
        },
        async fetchSuggestedMissions(missionSlug: string) {
            const questStore = useQuestStore();

            const missionSuggestionEvent = questStore.questEvents.findIndex((event) => event.type === QuestEventTypeEnum.MISSION_SUGGESTION);

            if (missionSuggestionEvent === -1) {
                return;
            }

            try {
                const response = await getSuggestedMissions(missionSlug);
                questStore.suggestedMissions = response.data.data;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to fetch suggested missions:', error);
            }
        },
        async verifySubmissionAndUpdateRoundState(payload?: string[] | string | number) {
            const questRoundDataStore = useQuestRoundDataStore();

            const questStore = useQuestStore();

            try {
                const response = await verifyRoundTaskSubmission(questRoundDataStore.currentQuest?.uuid, payload);

                const events = response.quest_events;
                if (events) {
                    questStore.questEvents = events;

                    await this.fetchSuggestedMissions(questRoundDataStore.roundData.missionSlug);
                } else {
                    questStore.questEvents = [];
                }

                const status = response.validation_status;

                if (status === QuestActionValidationResultEnum.CORRECT) {
                    this.handleCorrectSubmission(response);
                }

                if (status === QuestActionValidationResultEnum.INCORRECT) {
                    this.handleIncorrectSubmission(response);
                }

                questStore.referralToken = response.user.quest_profile.referrals?.referral_token;
            } catch (error) {
                handleHttpError(error);

                throw error;
            } finally {
                questStore.heartLost = false;

                if (questStore.questEvents?.length) {
                    this.handleQuestEvents();
                }

                questStore.validatingAction = false;
            }
        },
        setPreRoundData(data?: PreRoundData) {
            this.preRoundData.title = data?.title || '';
            this.preRoundData.subtitle = data?.subtitle || '';
            this.preRoundData.description = data?.description || [''];
            this.preRoundData.image.url = data?.image?.url || '';
            this.preRoundData.order = data?.order || null;
            this.preRoundData.total_round_count = data?.total_round_count || null;
            this.preRoundData.content = data?.content || null;
            this.preRoundData.quest_mission = data?.quest_mission || null;
            this.preRoundData.uuid = data?.uuid || null;

            const sponsorList = data?.quest_sponsors || null;

            if (sponsorList) {
                this.preRoundData.quest_sponsors = sponsorList;
            }
        },
        handleIncorrectSubmission(response: QuestTaskValidationData) {
            const questRoundDataStore = useQuestRoundDataStore();
            const notificationStore = useNotificationStore();
            const questStore = useQuestStore();

            const roundType = questRoundDataStore.roundData.roundType;
            const isContentRound = roundType === QuestRoundTypeEnum.CONTENT_ROUND;
            const isTrackerRound = roundType === QuestRoundTypeEnum.TRACKER_ROUND;
            const isSocialRound = roundType === QuestRoundTypeEnum.SOCIAL_ROUND;
            const isBlockchainRound = roundType === QuestRoundTypeEnum.BLOCKCHAIN_ROUND;

            if (isContentRound || isTrackerRound) {
                questStore.validateAnswer(false, response.user.quest_profile.hearts, response.correct_answer || ['']);

                return;
            }

            if (isBlockchainRound || isSocialRound) {
                const currentQuestTaskCooldownToString = questRoundDataStore.currentQuest?.cooldown.toString();

                notificationStore.displayNotification(QuestNotificationTypesEnum.TASK_NOT_COMPLETED);

                setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN, currentQuestTaskCooldownToString);

                questStore.nextQuestionCountdown();

                return;
            }

            notificationStore.displayNotification(QuestNotificationTypesEnum.INCORRECT);
        },
        handleCorrectSubmission(response: QuestTaskValidationData) {
            const questRoundDataStore = useQuestRoundDataStore();
            const notificationStore = useNotificationStore();
            const questStore = useQuestStore();

            const roundType = questRoundDataStore.roundData.roundType;
            const isContentRound = roundType === QuestRoundTypeEnum.CONTENT_ROUND;
            const isTrackerRound = roundType === QuestRoundTypeEnum.TRACKER_ROUND;

            if (isContentRound || isTrackerRound) {
                questStore.validateAnswer(true, response.user.quest_profile.hearts, ['']);
            } else {
                const originalPosition = questRoundDataStore.roundData.questions.indexOf(questRoundDataStore.currentQuest);
                const allQuestionsOriginalPosition = questRoundDataStore.roundData.originalQuestions.findIndex((question: Quest) => question.uuid === questRoundDataStore.currentQuest?.uuid);
                questRoundDataStore.roundData.questionsStatus[originalPosition] = QuestTaskStatusEnum.CORRECT;
                questRoundDataStore.roundData.allQuestionsStatus[allQuestionsOriginalPosition] = QuestTaskStatusEnum.CORRECT;

                notificationStore.displayNotification(QuestNotificationTypesEnum.TASK_COMPLETED);
            }
        },
        // TODO: Quests - improve with handler map approach
        handleQuestEvents() {
            const questRoundDataStore = useQuestRoundDataStore();
            const questStore = useQuestStore();

            const roundCompleteEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is RoundCompleteEvent => event.type === QuestEventTypeEnum.ROUND_COMPLETE);
            const heartLostEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is HeartLostEvent => event.type === QuestEventTypeEnum.HEART_LOST);
            const streakIncrementedEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is StreakIncrementedEvent => event.type === QuestEventTypeEnum.QUEST_STREAK_INCREMENTED);
            const streakIncrementRewardedEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is StreakIncrementRewardedEvent => event.type === QuestEventTypeEnum.QUEST_STREAK_INCREMENT_REWARDED);
            const degreeChangedEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is DegreeChangedEvent => event.type === QuestEventTypeEnum.DEGREE_CHANGED);
            const streakRewardEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is StreakRewardCalculationEvent => event.type === QuestEventTypeEnum.QUEST_STREAK_REWARD_CALCULATION);
            const roundStrikeEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is RoundStrikeEvent => event.type === QuestEventTypeEnum.ROUND_STRIKE);
            const missionCompleteEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is MissionCompleteEvent => event.type === QuestEventTypeEnum.MISSION_COMPLETE);
            const examMissionCompletedEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is ExamCompletedEvent => event.type === QuestEventTypeEnum.EXAM_COMPLETED);
            const achievementsUnlockedEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is AchievementsUnlockedEvent => event.type === QuestEventTypeEnum.ACHIEVEMENTS_UNLOCKED);
            const customTaskSuggestionEvent = questStore.questEvents.find((event: { type: QuestEventTypeEnum; }): event is CustomTaskSuggestionEvent => event.type === QuestEventTypeEnum.CUSTOM_TASK_SUGGESTION);

            if (roundCompleteEvent) {
                questStore.stopTimer = true;
                questStore.nextRoundUuid = roundCompleteEvent.next_round_uuid;
                questStore.earnedBits = roundCompleteEvent.reward_amount?.amount ? Number(roundCompleteEvent.reward_amount?.amount) : 0;
                questStore.elapsedTime = roundCompleteEvent.elapsed_time;
                questStore.accuracy = Math.round(((questRoundDataStore.roundData.originalQuestions.length - roundCompleteEvent.second_attempts_count) / questRoundDataStore.roundData.originalQuestions.length) * 100);
                questStore.roundIsCompleted = true;
            }

            if (heartLostEvent) {
                questStore.heartLost = true;
            }

            if (achievementsUnlockedEvent) {
                questStore.unlockedAchievements = achievementsUnlockedEvent.unlocked_achievements;
            }

            if (examMissionCompletedEvent) {
                questStore.examMissionCompletedEvent = {
                    score: examMissionCompletedEvent?.score,
                    mission_title: examMissionCompletedEvent?.mission_title,
                    total_bits_collected: examMissionCompletedEvent?.total_bits_collected,
                    first_round_uuid: examMissionCompletedEvent?.first_round_uuid,
                };
            }

            if (degreeChangedEvent) {
                questRoundDataStore.userProfile.degree.current_degree = degreeChangedEvent.new_value;
            }

            if (streakIncrementedEvent) {
                questStore.streakCount = streakIncrementedEvent.streak_count;
            }

            if (streakIncrementRewardedEvent) {
                questStore.streakRewardAmount = streakIncrementRewardedEvent.streak_reward_amount;
            }

            if (streakRewardEvent) {
                questStore.streakReward = streakRewardEvent.streak_reward;
            }

            if (roundStrikeEvent) {
                questStore.roundStrikeReward = roundStrikeEvent.strike_reward_amount;
            }

            if (missionCompleteEvent) {
                questStore.missionCompleteTitle = missionCompleteEvent.mission_title;
                questStore.missionCompleteReward = missionCompleteEvent.reward_amount?.amount ? missionCompleteEvent.reward_amount?.amount : 0;
            }

            if (customTaskSuggestionEvent) {
                questStore.customTask = customTaskSuggestionEvent.task;
            }
        },
    },
});
