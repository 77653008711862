import { useUserStore } from '../../../quests/dashboard/UserStore';
import { UserWalletData } from '../../types/types';
import { WalletAddressData } from '@/js/quests/dashboard/types/types';

export function isWalletAlreadyAdded(walletAddress: string): boolean {
    const userStore = useUserStore();

    return userStore.walletAddresses?.some((address: WalletAddressData): boolean => address.address === walletAddress) ?? false;
}

export function addNewWallet(walletData: UserWalletData): void {
    const userStore = useUserStore();

    if (Array.isArray(userStore.walletAddresses)) {
        const newWalletAddress: WalletAddressData = {
            address: walletData.wallet_address,
            chain_name: walletData.chain_name,
            is_primary: !userStore.walletAddresses?.length,
        };

        userStore.walletAddresses.push(newWalletAddress);
    }
}
