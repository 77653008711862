import { Web3ProviderEnum } from '../../types/enums';

export async function preloadWrapperFunction(wrapperName: string): Promise<unknown> {
    const module = await import('./walletConnectHandler');

    switch (wrapperName) {
        case Web3ProviderEnum.COINBASE:
            return module.coinbaseConnectWrapper;
        case Web3ProviderEnum.METAMASK:
            return module.metamaskConnectWrapper;
        default:
            return null;
    }
}
