import { filterMap, SortOptionsEnum, GuildTypeOptionsEnum } from '../types/filters';

export const createPaginationQueryParams = ({ page, perPage, sort }: {page: number, perPage: number, sort?: SortOptionsEnum}) => {
    const params = {
        page: page.toString(),
        perPage: perPage.toString(),
        ...(sort && { sort: sort.toString() }),
    };

    return new URLSearchParams(params).toString();
};

export const createFiltersQueryParams = ({ search = '', guildType }: { search: string, guildType?: GuildTypeOptionsEnum}) => {
    const queryParamsObject = {};

    if (search !== '') {
        queryParamsObject['filter[search]'] = search;
    }

    if (guildType && guildType in filterMap) {
        const { key, value } = filterMap[guildType];
        queryParamsObject[key] = value;
    }

    return new URLSearchParams(queryParamsObject).toString();
};
