export enum SortOptionsEnum {
    NEWEST = '-created_at',
    TRENDING = '-trending_rate',
}

export enum GuildTypeOptionsEnum {
    ALL = 'all',
    VERIFIED = 'verified',
    FOLLOWED = 'followed',
    NOT_FOLLOWED = 'not-followed',
    OWNED = 'owned',
}

export const filterMap: Partial<Record<GuildTypeOptionsEnum, {
    key: string,
    value: GuildTypeOptionsEnum.VERIFIED | boolean
}>> = {
    [GuildTypeOptionsEnum.OWNED]: { key: 'filter[owned_guilds]', value: true },
    [GuildTypeOptionsEnum.FOLLOWED]: { key: 'filter[followed_guilds]', value: true },
    [GuildTypeOptionsEnum.NOT_FOLLOWED]: { key: 'filter[followed_guilds]', value: false },
    [GuildTypeOptionsEnum.VERIFIED]: { key: 'filter[verification_status]', value: GuildTypeOptionsEnum.VERIFIED },
};

export interface FiltersType {
    search: string,
    sort: SortOptionsEnum,
    guildType: GuildTypeOptionsEnum,
}
