export function setMetaTag(selector: string, property: string, content: string) {
    const metaTag = document.querySelector(selector);

    if (metaTag && property && content) {
        metaTag.setAttribute(property, content);
    }
}

export function setOpenGraphMetaTags(title: string, description: string, url: string) {
    setMetaTag('meta[property="og:title"]', 'content', title);
    setMetaTag('meta[property="og:description"]', 'content', description);
    setMetaTag('meta[property="og:url"]', 'content', url);
}

export function setTwitterMetaTags(title: string, description: string) {
    setMetaTag('meta[name="twitter:title"]', 'content', title);
    setMetaTag('meta[name="twitter:description"]', 'content', description);
}

export function setMetaTitle(title: string) {
    if (title) {
        document.title = title;
    }
}

export function setMetaDescription(description: string) {
    setMetaTag('meta[name="description"]', 'content', description);
}
