import { defineStore } from 'pinia';
import { useQuestStore } from '../QuestStore';
import { QuestNotificationTypesEnum, QuestSoundTypesEnum } from '@/js/quests/game/types/enums';
import { playSound } from '@/js/quests/game/helpers/gameSoundHelper';

interface NotificationStore {
    shouldShowNotification: boolean;
    notificationType: QuestNotificationTypesEnum | null;
    notificationMessage: string;
}

export const useNotificationStore = defineStore({
    id: 'notificationStore',
    state: (): NotificationStore => ({
        shouldShowNotification: false,
        notificationType: null,
        notificationMessage: '',
    }),
    actions: {
        displayNotification(type: QuestNotificationTypesEnum, correctAnswer = ''): void {
            const questState = useQuestStore();
            this.notificationMessage = type;
            this.notificationType = type;
            questState.correct = correctAnswer;

            let success: boolean | null = null;

            success = type === QuestNotificationTypesEnum.TASK_COMPLETED || type === QuestNotificationTypesEnum.CORRECT;

            setTimeout(() => {
                this.setShowNotificationAndPlaySound(true, success as boolean);
            }, 100);
        },
        setShowNotificationAndPlaySound(value: boolean, success: boolean): void {
            this.shouldShowNotification = value;
            playSound(success ? QuestSoundTypesEnum.SUCCESS : QuestSoundTypesEnum.FAILED);
        },
    },
});
