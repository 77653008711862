export interface Web3ApiEndpoints {
    ADD_USER_WALLET: string;
    UPDATE_USER_WALLET: string;
    GET_OTP_CODE: string;
}

export const web3ApiEndpoints: Web3ApiEndpoints = {
    ADD_USER_WALLET: 'user/wallets',
    UPDATE_USER_WALLET: 'user/wallets/update',
    GET_OTP_CODE: 'web3-otp',
};
