import { QuestTrackerTaskAnswerMagnitude } from '../types/enums';

export const getSuffixLetter = (magnitude: QuestTrackerTaskAnswerMagnitude): string => QuestTrackerTaskAnswerMagnitude[magnitude];

export const createTrackerPlaceholder = (intDigits: number, precision: number | null, magnitude: number | null): string => {
    const integerPart: string = ' _ '.repeat(intDigits);
    const precisionPart: string = precision !== null && precision !== 0 ? `.${' _ '.repeat(precision)}` : '';
    const magnitudePart: string = magnitude !== null && magnitude !== 0 ? ` ${getSuffixLetter(magnitude)}` : '';

    return `${integerPart}${precisionPart}${magnitudePart}`;
};

export const parseHostname = (url: string): string => {
    const parsedUrl = new URL(url);

    return parsedUrl.hostname;
};

export const getCharBasedOnIndex = (index: number) => String.fromCharCode(65 + index);
