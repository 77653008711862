import { VITE_APP_URL } from '@/js/helpers/environment';

export function closeCurtains(redirectLink?: string): void {
    const topMenuElements = document.querySelectorAll('.top-menu');
    const navbarElements = document.querySelectorAll('.navbar');
    const curtainsLeft = document.querySelectorAll('.curtain-close.left');
    const curtainsRight = document.querySelectorAll('.curtain-close.right');
    const curtains = [...curtainsLeft, ...curtainsRight];

    for (let k = 0; k < curtains.length; k++) {
        const curtain = curtains[k] as HTMLElement;
        curtain.style.visibility = 'visible';
        curtain.style.transform = 'translateX(0)';
    }

    for (let i = 0; i < topMenuElements.length; i++) {
        const topMenuElement = topMenuElements[i] as HTMLElement;
        topMenuElement.style.position = 'static';
    }

    for (let j = 0; j < navbarElements.length; j++) {
        const navbarElement = navbarElements[j] as HTMLElement;
        navbarElement.style.position = 'static';
    }

    document.body.style.overflowX = 'hidden';

    setTimeout(() => {
        if (redirectLink) {
            const fullUrl = new URL(redirectLink, VITE_APP_URL);
            window.location.href = fullUrl.toString();
        } else {
            for (let m = 0; m < curtains.length; m++) {
                const curtain = curtains[m] as HTMLElement;
                if (curtain.classList.contains('left')) {
                    curtain.style.transform = 'translateX(-100%)';
                } else if (curtain.classList.contains('right')) {
                    curtain.style.transform = 'translateX(100%)';
                }

                curtain.style.visibility = 'hidden';
            }
        }
    }, 1000);
}
