import useAxiosRepository from '../../../../../repositories/BaseRepository';
import { GuildsApiResponse, SuggestedGuildsApiResponse } from '../types/guilds';
import { createFiltersQueryParams, createPaginationQueryParams } from '../helpers/queryParams';
import { FiltersType } from '../types/filters';
import { PaginationType } from '../types/pagination';

const axiosRepo = useAxiosRepository();

const guildApiEndpoints = {
    GET_GUILDS: (params: string) => `quests/guilds?${params}`,
    GET_SUGGESTED_GUILDS: (params: string) => `quests/guilds/suggestions?${params}`,
};

export const getGuilds = async (
    { search, sort, guildType } : FiltersType,
    { page = 1, perPage } : PaginationType = {},
    showError = true,
): Promise<GuildsApiResponse> => {
    const queryParams = createPaginationQueryParams({ page, perPage, sort });
    const filtersParams = createFiltersQueryParams({ search, guildType });
    const joinedParams = filtersParams ? `${filtersParams}&${queryParams}` : queryParams;

    const api = guildApiEndpoints.GET_GUILDS(joinedParams);

    return axiosRepo.get(api, { showError });
};

export const getSuggestedGuilds = async (
    search: string,
    { page, perPage }: PaginationType = {},
    showError = true,
): Promise<SuggestedGuildsApiResponse> => {
    const queryParams = createPaginationQueryParams({ page, perPage });
    const filtersParams = createFiltersQueryParams({ search });
    const joinedParams = filtersParams ? `${filtersParams}&${queryParams}` : queryParams;

    const api = guildApiEndpoints.GET_SUGGESTED_GUILDS(joinedParams);

    return axiosRepo.get(api, { showError });
};
