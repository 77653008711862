import useAxiosRepository from '../../../repositories/BaseRepository';
import { dashboardApiEndpoints } from '../constants/dashboardApiEndpoints';
import { SidebarGuild } from '../types/sidebar';

const axiosRepo = useAxiosRepository();

export const followGuild = (slug: string, showError = true) => {
    const api = dashboardApiEndpoints.GUILD_FOLLOW;
    const payload = {
        slug,
    };

    return axiosRepo.post(api, payload, { showError });
};

export const followGuilds = (slugs: string[], showError = true) => {
    const api = dashboardApiEndpoints.GUILD_FOLLOW;
    const payload = {
        slugs,
    };

    return axiosRepo.post(api, payload, { showError });
};

export const unfollowGuild = (slug: string, showError = true) => {
    const api = dashboardApiEndpoints.GUILD_FOLLOW;
    const payload = {
        slug,
    };

    return axiosRepo.delete(api, payload, { showError });
};

export const getFollowedGuilds = (showError = true): Promise<{ data: SidebarGuild[] }> => {
    const api = dashboardApiEndpoints.GUILD_FOLLOW;

    return axiosRepo.get(api, { showError });
};
