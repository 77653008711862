import { RouteRecordName } from 'vue-router';
import router from '../../../routes/dashboard.routes';
import { ApiUserResponse, User } from '@/js/auth/types/types';
import { dashboardRoutesNames } from '@/js/quests/dashboard/constants/dashboardRoutesNames';
import { useAuthStore } from '../AuthStore';

export function handleAuthResponse(response: ApiUserResponse, redirect = true): User | null {
    const user = response?.data?.user;

    if (!user) {
        return null;
    }

    const currentRouteName: RouteRecordName | undefined = router.currentRoute.value.name;
    const isInQuestsRoutes: boolean = [
        dashboardRoutesNames.MISSIONS_ROUTE_NAME,
        dashboardRoutesNames.MISSION_ROUTE_NAME,
        dashboardRoutesNames.MISSION_PREVIEW_ROUTE_NAME,
        dashboardRoutesNames.GUILDS_ROUTE_NAME,
        dashboardRoutesNames.LEADERBOARD_ROUTE_NAME,
        dashboardRoutesNames.FRIENDS_ROUTE_NAME,
        dashboardRoutesNames.ENGAGE_ROUTE_NAME,
        dashboardRoutesNames.NFT_CERTIFICATE_VERIFICATION_ROUTE_NAME,
        dashboardRoutesNames.GUILD_ROUTE_NAME,
        dashboardRoutesNames.BONUS_ROUTE_NAME,
    ].includes(currentRouteName);

    if (!isInQuestsRoutes && redirect) {
        let intentParam = new URLSearchParams(window.location.search).get('intent');
        const redirectUrl = response.data.redirect_url;

        if (intentParam || redirectUrl) {
            if (intentParam) {
                try {
                    const url = new URL(intentParam, window.location.origin);
                    if (url.origin !== window.location.origin) {
                        intentParam = null;
                    }
                } catch (error) {
                    intentParam = null;
                }
            }

            window.location.href = intentParam ? decodeURIComponent(intentParam) : redirectUrl;
        }
    }

    return user;
}

export async function fetchUserOnMissionMobile(currentPath: string, isMobile: boolean): Promise<void> {
    const authStore = useAuthStore();

    if (currentPath === dashboardRoutesNames.MISSION_ROUTE_NAME && !authStore.user && isMobile) {
        await authStore.fetchUser();
    }
}
