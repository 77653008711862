import { defineStore } from 'pinia';
import { AxiosError } from 'axios';
import {
    ActiveUserRound,
    ActiveUserRoundData,
    DegreeNumbers,
    InitialRoundDataResponse,
    ISocialAccount,
    Notification,
    QuestsUserData,
    WalletAddressData,
} from '@/js/quests/dashboard/types/types';
import {
    addUserPassword,
    changeUserPassword,
    createInitialUserRound,
    deactivateUserAccount,
    feedback,
    getActiveUserRound,
    getTangibleReward,
    getUserData,
    postReport,
    updateTangibleReward,
    resetUserPassword,
    sendUserEmailVerification,
    sendUserPasswordReset,
    toggleSoundSetting,
    updateUserSocialAccount,
    verifyUserEmailVerificationCode,
} from '@/js/quests/dashboard/services/api';
import { cancelRoundByTimeout, cancelRoundByUserChoice } from '@/js/quests/game/services/api';
import { pushUserIdToDataLayer } from '@/js/quests/global/helpers/pushUserIdToDataLayer';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { showToast } from '@/js/helpers/toastHelpers';
import { errorDefaultMessages } from '@/js/quests/dashboard/constants/errorDefaultMessages';
import {
    PasswordResetPayload,
    SendUserPasswordPayload,
    TangibleRewardPayload,
} from '@/js/quests/dashboard/types/payloads';
import { updateUserWallet } from '@/js/store/web3/services/api';
import { SidebarGuild } from '@/js/quests/dashboard/types/sidebar';
import { getFollowedGuilds } from '@/js/quests/dashboard/services/guildFollowApi';
import { OWNER_ROLE } from '@/js/constants/generalConstants';
import { ReportPayload } from '@/js/quests/global/types/report';
import { NotificationModalTypeEnum } from '@/js/quests/dashboard/types/enums';

interface UserStoreState {
    user: QuestsUserData | null;
    avatarHash?: string;
    isLoading: boolean;
    error: null;
    bits: string | null;
    degree: DegreeNumbers | null;
    questProfileId: string | null;
    streakCount: number | null;
    hearts: number | null;
    isSoundsEnabled: boolean;
    isFetchingSounds: boolean;
    isHeartEnabled: boolean;
    hasNewNotifications: boolean;
    referralCount: number | null;
    secondLevelReferralCount: number | null;
    referralToken: string | null;
    socialAccounts: ISocialAccount[] | null;
    walletAddresses: WalletAddressData[] | null;
    primaryWalletAddress: string | null;
    isPrimaryNewWallet: boolean;
    selectedWallet: WalletAddressData | null;
    selectedSocialAccount: ISocialAccount | null;
    notifications: Notification[] | null;
    activeUserRoundData: ActiveUserRoundData | null;
    isLoadingActiveUserRoundData: boolean | null;
    initialUserRoundData: InitialRoundDataResponse | null;
    followedGuilds: SidebarGuild[];
    isFollowedGuildsFetched: boolean;
    isQuestsUserDataLoading: boolean;
    isQuestsUserFetchCompleted: boolean;
    isFeedbackSent: boolean;
    tangible: {
        id: string,
        completedAt: string | null,
        expiresAt: string,
        questMissionTitle: string,
        rewardTitle: string,
    }
}

export const useUserStore = defineStore({
    id: 'userStore',
    state: (): UserStoreState => ({
        user: null,
        avatarHash: null,
        isLoading: false,
        error: null,
        bits: null,
        degree: null,
        questProfileId: null,
        streakCount: null,
        hearts: null,
        isSoundsEnabled: true,
        isFetchingSounds: false,
        isHeartEnabled: false,
        hasNewNotifications: true,
        referralCount: null,
        secondLevelReferralCount: null,
        referralToken: null,
        socialAccounts: null,
        walletAddresses: null,
        primaryWalletAddress: null,
        isPrimaryNewWallet: false,
        selectedWallet: null,
        selectedSocialAccount: null,
        notifications: null,
        activeUserRoundData: null,
        isLoadingActiveUserRoundData: null,
        initialUserRoundData: null,
        followedGuilds: [],
        isFollowedGuildsFetched: false,
        isQuestsUserDataLoading: false,
        isQuestsUserFetchCompleted: false,
        isFeedbackSent: false,
        tangible: {
            id: '',
            completedAt: '',
            expiresAt: '',
            questMissionTitle: '',
            rewardTitle: '',
        },
    }),
    getters: {
        isNewUserSignup() {
            if (!this.isQuestsUserFetchCompleted) {
                return false;
            }

            if (!this.notifications) {
                return false;
            }

            const index = this.notifications.findIndex((notification) => notification.type === NotificationModalTypeEnum.RegistrationRewardReceived);

            return index !== -1;
        },
    },
    actions: {
        setIsLoading(payload: boolean) {
            this.isLoading = payload;
        },
        setIsLoadingActiveUserRoundData(payload: boolean) {
            this.isLoadingActiveUserRoundData = payload;
        },
        setError(payload: AxiosError) {
            this.error = payload;
        },
        setIsSoundsEnabled(payload: boolean) {
            this.isSoundsEnabled = payload;
        },
        async sendPasswordReset(payload: SendUserPasswordPayload) {
            this.setIsLoading(true);

            try {
                await sendUserPasswordReset(payload);
            } catch (error) {
                if (error.response.data.errors) {
                    this.setError(error.response.data.errors);
                }

                throw error;
            } finally {
                this.setIsLoading(false);
            }
        },
        async resetPassword(payload: PasswordResetPayload) {
            this.setIsLoading(true);

            try {
                await resetUserPassword(payload);
            } catch (error) {
                if (error.response.data.errors) {
                    this.setError(error.response.data.errors);
                }

                throw error;
            } finally {
                this.setIsLoading(false);
            }
        },
        async cancelRoundByTimeEnd() {
            await cancelRoundByTimeout(this.activeUserRoundData?.uuid || '');
        },
        async cancelRoundByUserChoice() {
            try {
                await cancelRoundByUserChoice(this.activeUserRoundData?.uuid || '');
                await this.fetchActiveUserRoundData();
            } catch (error) {
                showToast({
                    message: error.response?.data?.message || errorDefaultMessages.CANCEL_ROUND_BY_USER,
                });
            }
        },
        setActiveUserRoundData(payload: ActiveUserRound | null) {
            if (payload && Array.isArray(payload.data)) {
                this.activeUserRoundData = null;
            } else {
                this.activeUserRoundData = payload ? payload.data : null;
            }
        },
        async fetchActiveUserRoundData() {
            this.setIsLoadingActiveUserRoundData(true);

            try {
                const response = await getActiveUserRound();
                this.setActiveUserRoundData(response);
            } catch (error) {
                showToast({
                    message: error.response?.data?.message || errorDefaultMessages.FETCH_ACTIVE_USER_ROUND_DATA,
                });
            } finally {
                this.setIsLoading(false);
                this.setIsLoadingActiveUserRoundData(false);
            }
        },
        async fetchQuestsUserData() {
            this.setIsLoading(true);
            this.isQuestsUserDataLoading = true;

            try {
                const response = await getUserData();

                this.user = response;
                const { quest_account, quest_profile } = response.data;
                const notifications = response.notifications;

                if (quest_account) {
                    this.bits = quest_account.balance.amount;
                }

                if (quest_profile) {
                    this.degree = quest_profile.degree;
                    this.hearts = quest_profile.hearts;
                    this.questProfileId = quest_profile.id;

                    pushUserIdToDataLayer(this.questProfileId);

                    if (quest_profile.quest_streak) {
                        this.streakCount = quest_profile.quest_streak.streak_count;
                    }

                    if (quest_profile.settings) {
                        this.isSoundsEnabled = quest_profile.settings.is_sounds_enabled;
                        this.isHeartEnabled = quest_profile.settings.is_heart_enabled;
                        this.avatarHash = quest_profile.avatar_hash;
                    }

                    if (quest_profile.referrals) {
                        this.referralCount = quest_profile.referrals.referral_count;
                        this.secondLevelReferralCount = quest_profile.referrals.second_level_referral_count;
                        this.referralToken = quest_profile.referrals.referral_token;
                    }

                    if (quest_profile.wallets) {
                        this.walletAddresses = quest_profile.wallets.addresses;
                        this.primaryWalletAddress = quest_profile.wallets.primary_address;
                    }

                    if (quest_profile.social_accounts) {
                        this.socialAccounts = quest_profile.social_accounts;
                    }
                }

                if (notifications) {
                    this.notifications = notifications;
                }

                await this.fetchActiveUserRoundData();
            } catch (error) {
                this.setError(error.response.data.errors);
                this.setIsLoading(false);
            } finally {
                this.isQuestsUserDataLoading = false;
                this.isQuestsUserFetchCompleted = true;
            }
        },
        async toggleIsSoundsEnabled() {
            const toggleFetchingVariable = () => {
                this.isFetchingSounds = !this.isFetchingSounds;
            };

            try {
                toggleFetchingVariable();
                await toggleSoundSetting(this.isSoundsEnabled);
            } catch (error) {
                this.setError(error.response.data.errors);
            } finally {
                this.setIsSoundsEnabled(!this.isSoundsEnabled);
                setTimeout(() => {
                    toggleFetchingVariable();
                }, 150);
            }
        },
        async deactivateAccount() {
            try {
                await deactivateUserAccount();
            } catch (error) {
                this.setError(error.response.data.errors);
            } finally {
                setTimeout(() => {
                    window.location.href = questsRoutes.MISSIONS_ROUTE;
                }, 1000);
            }
        },
        async changePassword(currentPassword: string, newPassword: string, confirmPassword: string) {
            await changeUserPassword(currentPassword, newPassword, confirmPassword);
        },
        async addPassword(password: string, confirmPassword: string, showError?: boolean, showSuccess?: boolean) {
            await addUserPassword(password, confirmPassword, showError, showSuccess);
        },
        async sendEmailVerification(email: string) {
            await sendUserEmailVerification(email);
        },
        async verifyEmailVerificationCode(code: string, showError?: boolean, showSuccess?: boolean) {
            await verifyUserEmailVerificationCode(code, showError, showSuccess);
        },
        async feedback(content: string, email: string, recaptchaResponse: string) {
            this.isFeedbackSent = false;
            await feedback(content, email, recaptchaResponse);
            this.isFeedbackSent = true;
        },
        async sendReport(payload: ReportPayload) {
            await postReport(payload);
        },
        async createInitialRound(round_uuid: string, isPreview = false) {
            this.initialUserRoundData = await createInitialUserRound(round_uuid, isPreview);
        },
        async setUserWalletPrimary(wallet: WalletAddressData) {
            try {
                wallet.is_primary = true;
                const { data: { wallets } } = await updateUserWallet(wallet);

                if (wallets) {
                    this.walletAddresses = wallets;
                    this.primaryWalletAddress = wallets[0].address;
                }
            } catch (error) {
                wallet.is_primary = false;
            }
        },
        async setUserSocialAccountPrimary(socialAccount: ISocialAccount) {
            try {
                socialAccount.is_primary = true;
                const { data: { social_accounts } } = await updateUserSocialAccount(socialAccount);

                if (socialAccount) {
                    this.socialAccounts = social_accounts;
                }
            } catch (error) {
                socialAccount.is_primary = false;
            }
        },
        async fetchSidebarFollowedGuilds() {
            const { data } = await getFollowedGuilds();
            this.followedGuilds = data;
            this.isFollowedGuildsFetched = true;
        },
        setFollowedGuildAsSeen(slug: string) {
            const guild = this.followedGuilds.find((guild) => guild.slug === slug);
            guild.has_unseen_updates = false;
        },
        setOwnerRole() {
          this.user.data.role = OWNER_ROLE;
        },
        async getTangibleRewardData(id: string) {
            try {
                const result = await getTangibleReward(id);

                this.tangible = {
                    id: result.data.id,
                    expiresAt: result.data.expires_at,
                    completedAt: result.data.completed_at,
                    questMissionTitle: result.data.quest_mission.title,
                    rewardTitle: result.data.reward_title,
                };
            } catch (error) {
                // eslint-disable-next-line no-empty
            }
        },
        async submitTangibleRewardData(id: string, payload: TangibleRewardPayload) {
            await updateTangibleReward(id, payload);
        },
    },
});
