import { isIOS } from '@/js/auth/helpers/deviceUtils';
import { getUserSignedAuthUrl } from '../../auth/services/api';
import { Web3ProviderEnum } from '../types/enums';
import { useAuthStore } from '../../auth/AuthStore';
import { COINBASE_DAPP_AUTH_URL, METAMASK_DAPP_AUTH_URL } from '../constants/walletAuthUrl';

export function getCurrentPath(): string {
    const path = window.location.pathname;

    return path.startsWith('/') ? path.substring(1) : path;
}

export async function constructMetamaskMobileRedirectRoute(currentPath: string): Promise<string> {
    const authStore = useAuthStore();
    const metamaskDappAuthUrl = METAMASK_DAPP_AUTH_URL;
    const signedUri = authStore.user
        ? await getUserSignedAuthUrl(currentPath, Web3ProviderEnum.METAMASK)
        : undefined;

    if (authStore.user) {
        if (isIOS()) {
            const cleanUrl = signedUri.data.url.replace(/^https?:\/\//, '');

            return `${metamaskDappAuthUrl}${cleanUrl}`;
        }

        return `${metamaskDappAuthUrl}${signedUri.data.url}`;
    }

    const targetRoute = `${window.location.hostname}/connect?type=${Web3ProviderEnum.METAMASK}`;

    return `${metamaskDappAuthUrl}${targetRoute}`;
}

export async function constructCoinbaseMobileRedirectRoute(currentPath: string): Promise<string> {
    const authStore = useAuthStore();
    const coinbaseDappAuthUrl = COINBASE_DAPP_AUTH_URL;

    if (authStore.user) {
        const signedUri = await getUserSignedAuthUrl(currentPath, Web3ProviderEnum.COINBASE);
        const encodedUrl = encodeURIComponent(signedUri.data.url);

        return `${coinbaseDappAuthUrl}${encodedUrl}`;
    }

    const encodedUrl = encodeURIComponent(`${window.location.origin}/connect?type=${Web3ProviderEnum.COINBASE}`);

    return `${coinbaseDappAuthUrl}${encodedUrl}`;
}
