import { defineStore } from 'pinia';
import { AxiosError } from 'axios';
import {
    getMissionData, getMissionsData, getMissionPreviewData, getPreviousMissionData, setFireBaseToken,
} from '@/js/quests/dashboard/services/api';
import {
    Mission,
    MissionApiResponse,
    Missions, MissionsFilters,
    PreviousMissionApiResponse,
} from '@/js/quests/dashboard/types/types';
import { CompanionFooterRaisedEnum } from '@/js/quests/dashboard/types/enums';

export interface DashboardStoreState {
    isMissionsLoading: boolean,
    isMissionLoading: boolean,
    isLoadingUserMissions: boolean,
    error: AxiosError | null,
    missionsData: Missions | null,
    userStartedMissionsData: Missions | null,
    completedMissionsData: Missions | null,
    missionData: MissionApiResponse | null,
    previousMissionData: PreviousMissionApiResponse | null,
    isCompanionRaised: {
        isMissionActive: boolean,
        isSelectedMissionFooterShown: boolean,
        isMissionsFooterShown: boolean,
    },
    userMissionsPage: number,
    userMissionsLastPage: number;
    hasMoreUserMissions: boolean,
    missionPage: number,
    hasMoreData: boolean,
    filters: {
        search: string;
        reward_type: string | null;
        reward_sub_type: string | null;
        guild_verification_status: string | null;
        followed_guilds: boolean | null;
        started_by_user: boolean | null;
        completed_by_user: boolean | null;
    };
    filterDropdownsValues: {
        guildType: string;
        rewardType: string;
        missionProgress: string;
        sort: string;
    };
    isMissionsDataFetchCompleted: boolean;
    isMissionDataFetchCompleted: boolean;
    isUserStartedMissionsDataFetchCompleted: boolean;
    slider: {
        slides: Mission[],
        error: AxiosError | null,
        isLoading: boolean,
        isFetchCompleted: boolean,
        startedByUser: boolean | null,
        completedByUser: boolean | null,
    },
    recommendedMissions: {
        list: Mission[],
        isLoading: boolean,
        isFetchCompleted: boolean,
    },
}

export const useDashboardStore = defineStore({
    id: 'dashboard',
    state: (): DashboardStoreState => ({
        isMissionsLoading: false,
        isMissionLoading: false,
        isLoadingUserMissions: false,
        error: null,
        missionsData: null,
        userStartedMissionsData: { data: [] },
        completedMissionsData: { data: [] },
        missionData: null,
        previousMissionData: null,
        isCompanionRaised: {
            isMissionActive: false,
            isSelectedMissionFooterShown: false,
            isMissionsFooterShown: false,
        },
        // Pagination state for user started missions
        userMissionsPage: 1,
        userMissionsLastPage: 1,
        hasMoreUserMissions: true,
        // Pagination state for all missions
        missionPage: 1,
        hasMoreData: true,
        filters: {
            search: '',
            reward_type: null,
            reward_sub_type: null,
            guild_verification_status: null,
            followed_guilds: null,
            started_by_user: null,
            completed_by_user: null,
        },
        filterDropdownsValues: {
            guildType: 'all',
            rewardType: 'all',
            missionProgress: 'all',
            sort: '-published_at',
        },
        isMissionsDataFetchCompleted: false,
        isMissionDataFetchCompleted: false,
        isUserStartedMissionsDataFetchCompleted: false,
        slider: {
            slides: [],
            error: null,
            isLoading: false,
            isFetchCompleted: false,
            startedByUser: null,
            completedByUser: null,
        },
        recommendedMissions: {
            list: [],
            isLoading: false,
            isFetchCompleted: false,
        },
    }),
    getters: {
        hasMissionRounds(): boolean {
            return !!this.missionData?.data?.quest_rounds?.length;
        },
    },
    actions: {
        hasActiveFilters() {
            return !!this.filters.search
                || !!this.filters.followed_guilds
                || !!this.filters.guild_verification_status
                || !!this.filters.reward_type
                || !!this.filters.reward_sub_type
                || !!this.filters.started_by_user
                || !!this.filters.completed_by_user;
        },
        async fetchSliderData({ page = 1, perPage = 5 }: { page?: number; perPage?: number } = {}) {
            this.slider.isLoading = true;

            const sortString = '-published_at';
            const startedByUser = this.slider.startedByUser;
            const completedByUser = this.slider.completedByUser;

            const filters: MissionsFilters = {
                ...(startedByUser !== undefined && startedByUser !== null && { started_by_user: startedByUser }),
                ...(completedByUser !== undefined && completedByUser !== null && { completed_by_user: completedByUser }),
            };

            try {
                const response = await getMissionsData(filters, sortString, page, perPage);

                this.slider.slides = response.data;
            } catch (error) {
                this.slider.error = error as AxiosError;
            } finally {
                this.slider.isLoading = false;
                this.slider.isFetchCompleted = true;
            }
        },
        async fetchMissionsData({ page = 1, perPage = 12 }: { page?: number; perPage?: number } = {}) {
            this.isMissionsLoading = true;

            const {
                search, reward_type, reward_sub_type, guild_verification_status, followed_guilds, started_by_user, completed_by_user,
            } = this.filters;
            const sortString = this.filterDropdownsValues.sort || '-published_at';

            const filters: MissionsFilters = {
                ...(search && { search }),
                ...(reward_type && { reward_type }),
                ...(reward_sub_type && { reward_sub_type }),
                ...(started_by_user !== undefined && started_by_user !== null && { started_by_user }),
                ...(completed_by_user && { completed_by_user }),
                ...(guild_verification_status && { guild_verification_status }),
                ...(followed_guilds !== null && { followed_guilds }),
            };

            try {
                const newMissionsData = await getMissionsData(filters, sortString, page, perPage);

                if (page === 1) {
                    this.missionsData = newMissionsData;
                } else if (newMissionsData?.data?.length) {
                    this.missionsData.data = [
                        ...this.missionsData.data,
                        ...newMissionsData.data,
                    ];
                }

                this.missionPage = newMissionsData?.meta?.current_page;
                this.hasMoreData = newMissionsData?.meta?.last_page && page < newMissionsData.meta.last_page;
            } catch (error) {
                this.error = error as AxiosError;
            } finally {
                this.isMissionsLoading = false;
                this.isMissionsDataFetchCompleted = true;
            }
        },
        async fetchUserStartedMissionsData({ page = 1, perPage = 50 }: { page?: number; perPage?: number } = {}) {
            this.isLoadingUserMissions = true;

            const {
                title, reward_type, reward_sub_type, guild_verification_status, followed_guilds,
            } = this.filters;
            const sortString = this.filterDropdownsValues.sort || '-published_at';

            const filters: MissionsFilters = {
                started_by_user: true,
                ...(title && { title }),
                ...(reward_type && { reward_type }),
                ...(reward_sub_type && { reward_sub_type }),
                ...(guild_verification_status && { guild_verification_status }),
                ...(followed_guilds !== null && { followed_guilds }),
            };

            try {
                const response = await getMissionsData(filters, sortString, page, perPage);

                if (page === 1) {
                    this.userStartedMissionsData = response;
                } else if (response?.data?.length) {
                    this.userStartedMissionsData.data = [
                        ...this.userStartedMissionsData.data,
                        ...response.data,
                    ];
                }

                this.hasMoreUserMissions = response?.data?.length === perPage;
                this.userMissionsLastPage = response?.meta?.last_page;
            } catch (error) {
                this.error = error as AxiosError;
            } finally {
                this.isLoadingUserMissions = false;
                this.isUserStartedMissionsDataFetchCompleted = true;
            }
        },
        async fetchMissionData(guildSlug: string, slug: string) {
            this.isMissionLoading = true;
            this.missionData = await getMissionData(guildSlug, slug);
            this.isMissionLoading = false;
            this.isMissionDataFetchCompleted = true;
        },
        async fetchMissionDataForPreview(guildSlug: string, slug: string) {
            this.isMissionLoading = true;
            this.missionData = await getMissionPreviewData(guildSlug, slug);
            this.isMissionLoading = false;
            this.isMissionDataFetchCompleted = true;
        },
        async fetchPreviousMissionData(slug: string) {
            this.previousMissionData = await getPreviousMissionData(slug);
        },
        async fetchRecommendedMissions(isLoggedIn) {
            const filters: MissionsFilters = {
                ...(isLoggedIn ? { started_by_user: false } : {}),
            };

            try {
                this.recommendedMissions.isLoading = true;
                const response = await getMissionsData(filters, '-published_at', 1, 8);
                this.recommendedMissions.list = response.data;
            } finally {
                this.recommendedMissions.isLoading = false;
                this.recommendedMissions.isFetchCompleted = true;
            }
        },
        setIsCompanion(value: boolean, key: CompanionFooterRaisedEnum) {
            this.isCompanionRaised[key] = value;
        },
        async sendFireBaseToken(token: string) {
            await setFireBaseToken(token);
        },
    },
});
