import { TIME_CONSTANTS_IN_MS } from '@/js/constants/timeConstants';

const OTP_DATA_KEY = 'otpData';

export function getCachedOtp(): string | null {
    const storedData = localStorage.getItem(OTP_DATA_KEY);

    if (storedData) {
        const { otp, timestamp } = JSON.parse(storedData);
        const currentTime: number = Date.now();

        if (currentTime - timestamp <= TIME_CONSTANTS_IN_MS.ONE_HOUR.value) {
            return otp;
        }
    }

    return null;
}

export function storeOtpData(otp: string): void {
    const otpData = {
        otp,
        timestamp: Date.now(),
    };
    localStorage.setItem(OTP_DATA_KEY, JSON.stringify(otpData));
}
