import { defineStore } from 'pinia';
import { useToast } from 'vue-toast-notification';
import { isMobileDevice } from '@/js/auth/helpers/deviceUtils';
import { addUserWallet, getOtpCode } from './services/api';
import { preloadWrapperFunction } from './helpers/wallet/walletWrapperLoader';
import { getTrans } from '@/js/helpers/getTrans';
import { OtpResponse, UserWalletData } from './types/types';
import { useUserStore } from '../quests/dashboard/UserStore';
import { useModalStore } from '../quests/game/modules/ModalStore';
import { useQuestRoundDataStore } from '../quests/game/modules/QuestRoundDataStore';
import { useAuthStore } from '../auth/AuthStore';
import { Web3ProviderEnum } from './types/enums';
import { hasWeb3Extension } from './helpers/ethereumUtils';
import {
    constructCoinbaseMobileRedirectRoute,
    constructMetamaskMobileRedirectRoute,
    getCurrentPath,
} from './helpers/urlHelpers';
import { handleWalletError } from './helpers/wallet/walletErrorHelpers';
import { fetchUserOnMissionMobile } from '../auth/helpers/authHelpers';
import { getCachedOtp, storeOtpData } from './helpers/otp/otpHelpers';
import { isWalletAlreadyAdded } from './helpers/wallet/walletUtils';
import { dashboardRoutesPaths } from '@/js/quests/dashboard/constants/dashboardRoutesPaths';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { HTTP_STATUS } from '@/js/enums/httpStatus';

interface Web3State {
    isMetaMaskExtensionEnabled: boolean;
    showMobileCoinbaseModal: boolean;
    isGame: boolean;
    otpCode: string | null;
    isMobileApp: boolean;
}

export const useWeb3Store = defineStore({
    id: 'web3',
    state: (): Web3State => ({
        isMetaMaskExtensionEnabled: true,
        showMobileCoinbaseModal: false,
        isGame: false,
        otpCode: null,
        isMobileApp: false,
    }),
    actions: {
        setOtpCode(otpCode: string | null): void {
            this.otpCode = otpCode;
        },
        setIsMobileApp(isMobileApp: boolean) {
            this.isMobileApp = isMobileApp;
        },
        async fetchOtp(): Promise<string | null> {
            try {
                const cachedOtp = getCachedOtp();

                if (cachedOtp !== null) {
                    return cachedOtp;
                }

                const response: OtpResponse = await getOtpCode();
                const otp = response.data.otp_code;

                if (otp) {
                    storeOtpData(otp);
                }

                return otp;
            } catch (error) {
                return null;
            }
        },
        async getWalletData(otp: string, wrapperFunction, wrapperName: string): Promise<UserWalletData | null> {
            try {
                const {
                    wallet_address,
                    signature,
                    message,
                    chain_name,
                    chain_id,
                } = await wrapperFunction(otp);

                return {
                    wallet_address,
                    signature,
                    message,
                    chain_name,
                    chain_id,
                    wallet_provider: wrapperName,
                };
            } catch (error) {
                handleWalletError(error, wrapperName);

                return null;
            }
        },
        async addWallet(walletData: UserWalletData): Promise<void> {
            try {
                const userStore = useUserStore();
                const questRoundDataStore = useQuestRoundDataStore();

                const path = window.location.pathname;

                walletData.short_success = this.isGame;

                if (path === questsRoutes.MISSION_ROUTE) {
                    walletData.is_primary = questRoundDataStore.userProfile.isPrimaryNewWallet;
                } else {
                    walletData.is_primary = userStore.isPrimaryNewWallet;
                }

                const walletsData = await addUserWallet(walletData);

                const modalStore = useModalStore();

                const walletAlreadyAdded: boolean = isWalletAlreadyAdded(walletData.wallet_address);

                if (walletAlreadyAdded) {
                    modalStore.setWalletConnectModal(false);

                    return;
                }

                if (this.isMobileApp) {
                    modalStore.setReturnToMobileAppModal(true);
                    await modalStore.waitForReturnToMobileAppModalClosure();
                }

                userStore.walletAddresses = walletsData.data.wallets;
                questRoundDataStore.userProfile.wallets = walletsData.data.wallets;
                userStore.primaryWalletAddress = walletData.wallet_address;

                modalStore.setWalletConnectModal(false);

                if (!userStore.isPrimaryNewWallet && path === dashboardRoutesPaths.SETTINGS_ROUTE_PATH) {
                    userStore.selectedWallet = walletsData.data.new_wallet;
                    modalStore.setConnectedWalletModal(true);
                }

                if (!questRoundDataStore.userProfile.isPrimaryNewWallet && path === questsRoutes.MISSION_ROUTE) {
                    questRoundDataStore.userProfile.selectedWallet = walletsData.data.new_wallet;
                    modalStore.setConnectedWalletModal(true);
                }

                questRoundDataStore.userProfile.isWalletConnected = true;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error adding wallet: ', error);
            }
        },
        async connectWithWrapper(wrapperName: string, fetchedOtp = null, redirect = true, addWallet = false): Promise<void> {
            let otp = fetchedOtp;

            if (addWallet) {
                const response = await getOtpCode();
                otp = response.data.otp_code;
            }

            const wrapperFunction = await preloadWrapperFunction(wrapperName);

            if (!wrapperFunction) {
                return;
            }

            try {
                if (!otp) {
                    otp = await this.fetchOtp();
                }

                if (!otp) {
                    // eslint-disable-next-line no-console
                    console.error('Error while generating OTP');

                    return;
                }
                const walletData = await this.getWalletData(otp, wrapperFunction, wrapperName);

                if (!walletData) {
                    // eslint-disable-next-line no-console
                    console.error('Failed to get wallet data');

                    return;
                }

                const payload = {
                    ...walletData,
                };

                if (!addWallet) {
                    try {
                        const authStore = useAuthStore();
                        await authStore.login(payload, redirect, this.isMobileApp);
                    } catch (error) {
                        if (error.response.status !== HTTP_STATUS.NOT_FOUND) {
                            useToast().open({
                                message: error.response?.data?.message || getTrans('quests-dashboard.somethingWentWrongToast', 'Something went wrong'),
                                type: 'error',
                                position: 'top-right',
                            });
                        }
                    }
                } else {
                    await this.addWallet(walletData);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('An error occurred:', error);
            }
        },
        async metamaskConnect(
            walletProvider: Web3ProviderEnum = Web3ProviderEnum.METAMASK,
            fetchedOtp = null,
            redirect = true,
            addWallet = false,
        ): Promise<void> {
            const currentPath = getCurrentPath();
            const isMobile = isMobileDevice();

            await fetchUserOnMissionMobile(currentPath, isMobile);

            if (isMobile && !hasWeb3Extension()) {
                window.location.href = await constructMetamaskMobileRedirectRoute(currentPath);
            } else if (hasWeb3Extension()) {
                await this.connectWithWrapper(walletProvider, fetchedOtp, redirect, addWallet);
            } else {
                this.isMetaMaskExtensionEnabled = false;
            }
        },
        async coinbaseConnect(fetchedOtp: string, redirect = true, addWallet = false): Promise<void> {
            const currentPath: string = getCurrentPath();
            const isMobile: boolean = isMobileDevice();

            await fetchUserOnMissionMobile(currentPath, isMobile);

            if (isMobile && !hasWeb3Extension()) {
                window.location.href = await constructCoinbaseMobileRedirectRoute(currentPath);

                return;
            }

            await this.connectWithWrapper(Web3ProviderEnum.COINBASE, fetchedOtp, redirect, addWallet);
        },
    },
});
