import * as Sentry from '@sentry/vue';
import { Web3ProviderEnum } from '../../types/enums';
import { defaultWeb3ErrorMessages } from '../../constants/error-messages/defaultWeb3ErrorMessages';
import { web3AuthErrorMessages } from '../../constants/error-messages/web3ErrorMessages';
import { getTrans } from '@/js/helpers/getTrans';
import { showToast } from '@/js/helpers/toastHelpers';

interface CustomError extends Error {
    response?: {
        data?: {
            message?: string;
        };
    };
}

export function handleWalletError(error: CustomError, wrapperName: string): void {
    const errorMessage = error.message;
    let toastMessage = '';

    if (wrapperName === Web3ProviderEnum.COINBASE && (!error || Object.keys(error).length === 0)) {
        return;
    }

    if (errorMessage.includes(defaultWeb3ErrorMessages.USER_REJECTED_THE_REQUEST)) {
        toastMessage = web3AuthErrorMessages.CONNECTION_CLOSED_BY_USER;
    } else if (errorMessage.includes(defaultWeb3ErrorMessages.RESOURCE_NOT_AVAILABLE)
        || errorMessage.includes(defaultWeb3ErrorMessages.ALREADY_PROCESSING)) {
        toastMessage = web3AuthErrorMessages.ALREADY_PROCESSING;
    } else if (errorMessage.includes(defaultWeb3ErrorMessages.USER_DENIED_MESSAGE_SIGNATURE)) {
        toastMessage = web3AuthErrorMessages.SIGNATURE_CLOSED_BY_USER;
    } else {
        toastMessage = error.response?.data?.message || getTrans('quests-dashboard.somethingWentWrongToast', 'Something went wrong');

        Sentry.captureException(errorMessage);
    }

    showToast({ message: toastMessage });
}
