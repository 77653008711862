import axios from 'axios';

import useAxiosRepository from '../../../repositories/BaseRepository';
import { APIEndpoints } from '../constants/APIEndpoints';
import {
    NextRoundData, PreRoundData, QuestTaskValidationData, RoundData, SuggestedMissionsResponse,
} from '../types/types';
import { UserSocialAccountsResponseData } from '../../dashboard/types/types';

const axiosRepo = useAxiosRepository();

export const getPreRoundData = async (roundUuid: string): Promise<PreRoundData> => {
    if (!roundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.GET_PRE_ROUND_DATA(roundUuid);

    const response = await axios.get<{ data: PreRoundData }>(api);

    return response.data.data;
};

export const createInitialUserRound = async (userRoundUuid: string): Promise<NextRoundData> => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.CREATE_INITIAL_USER_ROUND;

    const response = await axios.post(api, { round_uuid: userRoundUuid });

    return response.data.data;
};

export const startRoundAndGetData = async (userRoundUuid: string): Promise<RoundData> => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.START_ROUND_AND_GET_DATA(userRoundUuid);

    const response = await axios.post(api);

    return response.data.data;
};

export const verifyRoundTaskSubmission = async (actionUuid: string, payload?: string[] | string | undefined | number): Promise<QuestTaskValidationData> => {
    if (!actionUuid) {
        throw new Error('No action id provided');
    }

    const api = APIEndpoints.VERIFY_ROUND_TASK_SUBMISSION;
    const payloadData: { action_uuid: string, payload?: string[] | string | number } = { action_uuid: actionUuid };

    if (payload) {
        payloadData.payload = payload;
    }

    const response = await axios.post(api, payloadData);

    return response.data.data;
};

export const cancelRoundByUserChoice = async (userRoundUuid: string): Promise<void> => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.CANCEL_ROUND_BY_USER_CHOICE(userRoundUuid);

    const response = await axios.post(api);

    return response.data.data;
};

export const cancelRoundByTimeout = async (userRoundUuid: string): Promise<void> => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.CANCEL_ROUND_BY_TIMEOUT(userRoundUuid);

    try {
        await axios.post(api);
    } catch (e) {
        throw new Error('API operation failed');
    }
};

export const setIntroAsCompleted = async (userRoundUuid: string): Promise<void> => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.SET_INTRO_AS_COMPLETED(userRoundUuid);

    try {
        await axios.patch(api);
    } catch (e) {
        throw new Error('API operation failed');
    }
};

export const cancelRoundWithBeacon = (userRoundUuid: string) => {
    if (!userRoundUuid) {
        throw new Error('No param provided');
    }

    const api = APIEndpoints.CANCEL_ROUND_BY_USER_CHOICE(userRoundUuid);

    navigator.sendBeacon(api);
};

export const visitSiteCompleted = async (actionUuid: string, showError = true): Promise<{
    data: {
        resource_visited_at: string;
    },
    status: string}> => axiosRepo.patch(APIEndpoints.VISIT_SITE_COMPLETED, {
    action_uuid: actionUuid,
}, { showError });

export const getUserSocials = async (): Promise<UserSocialAccountsResponseData> => {
    const api = APIEndpoints.GET_USER_SOCIALS_ACCOUNTS;

    return axiosRepo.get(api);
};

export const getSuggestedMissions = async (missionSlug: string): Promise<SuggestedMissionsResponse> => {
    if (!missionSlug) {
        throw new Error('No mission slug provided');
    }

    const api = APIEndpoints.GET_SUGGESTED_MISSIONS(missionSlug);

    return axios.get(api);
};

export const postCustomTaskCompleted = async (id: string): Promise<void> => {
    if (!id) {
        throw new Error('No task id provided');
    }

    const api = APIEndpoints.SET_TASK_AS_COMPLETED(id);

    try {
        await axios.post(api);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};
