import { OtpResponse } from '../types/types';
import { web3ApiEndpoints } from '../constants/web3ApiEndpoints';
import useAxiosRepository from '../../../repositories/BaseRepository';
import { UserWalletsResponseData, WalletAddressData } from '@/js/quests/dashboard/types/types';

const axiosRepo = useAxiosRepository();

export const addUserWallet = async (
    walletData: object,
    showError = true,
    showSuccess = true,
): Promise<UserWalletsResponseData> => {
    const api = web3ApiEndpoints.ADD_USER_WALLET;

    return axiosRepo.post(api, walletData, { showError, showSuccess });
};

export const updateUserWallet = async (
    walletData: WalletAddressData,
    showError = true,
    showSuccess = true,
): Promise<UserWalletsResponseData> => {
    const api = web3ApiEndpoints.UPDATE_USER_WALLET;
    const payload = {
        wallet_address: walletData.address,
        is_primary: walletData.is_primary,
    };

    return axiosRepo.patch(api, payload, { showError, showSuccess });
};

export const getOtpCode = async (): Promise<OtpResponse> => {
    const api = web3ApiEndpoints.GET_OTP_CODE;

    return axiosRepo.get(api);
};
