import { useQuestRoundDataStore } from '@/js/store/quests/game/modules/QuestRoundDataStore';

export const playSound = (path: string): void => {
    const store = useQuestRoundDataStore();

    if (!store.userProfile.soundsEnabled) {
        return;
    }

    const audio: HTMLAudioElement = new Audio(path);
    audio.play().catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('Playing audio failed:', error);
    });
};
