import { defineStore } from 'pinia';
import { getGuildInfo, getMissionsData } from '@/js/quests/dashboard/services/api';
import { GuildInfo, Missions, MissionsFilters } from '@/js/quests/dashboard/types/types';
import { HTTP_STATUS } from '@/js/enums/httpStatus';

export interface GuildStoreState {
    guildInfo?: GuildInfo | null,
    isGuildInfoLoading: boolean,
    isGuildInfoFetchCompleted: boolean,
    guildMissions?: Missions | null,
    isMissionsLoading: boolean,
    isMissionsInfinityLoading: boolean,
    isMissionsFetchCompleted: boolean,
    currentMissionsPage: number,
    isMissionsLastPage: boolean,
    isGuildFollowed: boolean | null,
    guildFollowerCount: number | null,
    guildPlayerCount: number | null,
    createdAt: string,
    is404: boolean,
    filters: MissionsFilters;
    filterDropdownsValues: {
        rewardType: string;
        missionProgress: string;
        sort: string;
    };
}

export const useGuildStore = defineStore({
    id: 'guild',
    state: (): GuildStoreState => ({
        guildInfo: null,
        isGuildInfoLoading: false,
        isGuildInfoFetchCompleted: false,
        guildMissions: null,
        isMissionsLoading: false,
        isMissionsInfinityLoading: false,
        isMissionsFetchCompleted: false,
        currentMissionsPage: 1,
        isMissionsLastPage: false,
        isGuildFollowed: null,
        guildFollowerCount: null,
        guildPlayerCount: null,
        createdAt: null,
        is404: false,
        filters: {
            guild_slug: '',
            search: '',
        },
        filterDropdownsValues: {
            rewardType: 'all',
            missionProgress: 'all',
            sort: '-published_at',
        },
    }),
    getters: {
        hasActiveFilters():boolean {
            return !!this.filters.search
                || !!this.filters.followed_guilds
                || !!this.filters.guild_verification_status
                || !!this.filters.reward_type
                || !!this.filters.reward_sub_type
                || !!this.filters.started_by_user
                || !!this.filters.completed_by_user;
        },
    },
    actions: {
        resetGuildMissionsCurrentPage() {
            this.currentMissionsPage = 1;
        },
        increaseGuildMissionsCurrentPage() {
            this.currentMissionsPage += 1;
        },
        resetFilters() {
            this.filters.guild_slug = '';
            this.filters.search = '';

            this.filterDropdownsValues.rewardType = 'all';
            this.filterDropdownsValues.missionProgress = 'all';
            this.filterDropdownsValues.sort = '-published_at';
        },
        async fetchGuildMissions(filters: MissionsFilters, page: number, perPage: number) {
            try {
                this.isMissionsLoading = true;
                this.isMissionsFetchCompleted = false;

                const sortString = this.filterDropdownsValues.sort || '-published_at';

                const sanitizedFilters = Object.entries(filters).reduce((result, [filterKey, filterValue]) => {
                    if (filterValue !== null && filterValue !== undefined && filterValue !== '') {
                        result[filterKey] = filterValue;
                    }

                    return result;
                }, {} as MissionsFilters);

                const newMissionsData = await getMissionsData(sanitizedFilters, sortString, page, perPage);

                if (page === 1) {
                    this.resetGuildMissionsCurrentPage();
                    this.guildMissions = newMissionsData;
                } else if (newMissionsData?.data?.length) {
                    this.guildMissions.data = [
                        ...this.guildMissions.data,
                        ...newMissionsData.data,
                    ];
                }

                this.isMissionsLastPage = page >= newMissionsData.meta.last_page;
            } finally {
                this.isMissionsLoading = false;
                this.isMissionsFetchCompleted = true;
                this.isMissionsInfinityLoading = false;
            }
        },
        async fetchGuildInfo(slug: string) {
            try {
                this.isGuildInfoLoading = true;
                this.isGuildInfoFetchCompleted = false;
                this.guildInfo = (await getGuildInfo(slug))?.data;
                this.isGuildFollowed = this.guildInfo.is_followed;
                this.guildFollowerCount = this.guildInfo.follower_count;
                this.guildPlayerCount = this.guildInfo.player_count;
                this.createdAt = this.guildInfo.created_at;
                this.is404 = false;
            } catch (error) {
                const { response } = error;

                if (response && response.status === HTTP_STATUS.NOT_FOUND) {
                    this.is404 = true;
                }
            } finally {
                this.isGuildInfoLoading = false;
                this.isGuildInfoFetchCompleted = true;
            }
        },
    },
});
