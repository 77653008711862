import { defineStore } from 'pinia';
import RoleEnum from '../../manage/types/roleEnum';
import {
 fetchAdminUserData, fetchUserData, userLogin, userLogout,
} from './services/api';
import { handleAuthResponse } from './helpers/authHelpers';
import { useModalStore } from '../quests/game/modules/ModalStore';
import { ApiUserResponse, User } from '@/js/auth/types/types';
import { logoutSuccessHandler } from '@/js/auth/helpers/logoutSuccessHandler';
import { LoginUserPayload } from '@/js/quests/dashboard/types/payloads';
import { OWNER_ROLE } from '@/js/constants/generalConstants';

interface AuthStoreState {
    user: User | null;
    isLoading: boolean;
    error: object | null;
    isUserFetchCompleted: boolean;
    isReferralCodeStored: boolean;
}

export const useAuthStore = defineStore('AuthStore', {
    state: (): AuthStoreState => ({
        user: null,
        isLoading: false,
        error: null,
        isUserFetchCompleted: false,
        isReferralCodeStored: false,
    }),
    getters: {
        isUserLoggedIn() {
            if (this.user === null) {
                return false;
            }

            return Object.keys(this.user).length !== 0;
        },
    },
    actions: {
        setUser(user: User | null) {
            this.user = user;
        },
        setEmail(email: string) {
            if (this.user) {
                this.user.email = email;
            }
        },
        hasRole(role: string): boolean {
            return this.user?.role.includes(role);
        },
        isTranslator(): boolean {
            return this.hasRole(RoleEnum.TRANSLATOR);
        },
        isAssistant(): boolean {
            return this.hasRole(RoleEnum.ASSISTANT) || this.hasRole(RoleEnum.ASSISTANT_MANAGER);
        },
        isAdmin(): boolean {
            return this.hasRole(RoleEnum.ADMIN);
        },
        setLoading(payload: boolean) {
            this.isLoading = payload;
        },
        handleAuthResponse,
        async fetchUser() {
            this.setLoading(true);

            try {
                const response = window.location.pathname.startsWith('/dboard')
                    ? await fetchAdminUserData()
                    : await fetchUserData();

                this.user = response?.data?.user || null;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                this.setLoading(false);
                this.isUserFetchCompleted = true;
            }
        },
        async handleSuccessLogin(response: ApiUserResponse, redirect: boolean, isMobileApp: boolean) {
            const modalStore = useModalStore();

            if (isMobileApp) {
                modalStore.setReturnToMobileAppModal(true);
                await modalStore.waitForReturnToMobileAppModalClosure();
            }

            localStorage.removeItem('otpData');
            this.user = this.handleAuthResponse(response, redirect);
            modalStore.setAuthModal(false);
            modalStore.setWalletConnectModal(false);
        },
        async login(payload: LoginUserPayload, redirect = true, isMobileApp = false) {
            this.setLoading(true);

            try {
                const response = await userLogin(payload);

                if (response?.data?.extension_urls) {
                    localStorage.setItem('extension_urls', JSON.stringify(response.data.extension_urls));
                }

                await this.handleSuccessLogin(response, redirect, isMobileApp);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                throw error;
            } finally {
                const extensionUrls = localStorage.getItem('extension_urls');

                localStorage.clear();

                if (extensionUrls) {
                    localStorage.setItem('extension_urls', extensionUrls);
                }

                this.setLoading(false);
            }
        },
        async logout() {
            try {
                await userLogout();
                logoutSuccessHandler();
                localStorage.removeItem('extension_urls');
                window.postMessage({ action: 'removeExtensionUrls', payload: {} }, '*');
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },
        includesPermission(value: string) {
            return this.user?.permissions.includes(value);
        },
        setOwnerRole() {
            this.user.role = OWNER_ROLE;
        },
    },
});
