import { QuestState } from '@/js/quests/game/types';

export const useQuestState = (): QuestState => ({
    cooldownLeft: null,
    stopTimer: false,
    roundUuid: '',
    userRoundUuid: '',
    earnedBits: null,
    elapsedTime: null,
    accuracy: null,
    validatingAction: false,
    showIntro: null,
    introStep: 1,
    missionStarted: false,
    missionCountdownTime: 0,
    userAnswers: {
        entry: [],
        checkbox: [],
        select: [],
        sort: [],
        tracker: null,
    },
    correctAnswers: 0,
    selectedCheckboxes: [],
    answerDisabled: [],
    selectedButtonIndex: null,
    correct: '',
    confettiEffect: false,
    hasQuizStarted: false,
    hasGameEnded: false,
    hasTimeEnded: false,
    roundIsCompleted: null,
    nextRoundUuid: null,
    questEvents: null,
    heartLost: null,
    eventIndex: 0,
    streakCount: null,
    streakReward: null,
    streakRewardAmount: null,
    roundStrikeReward: null,
    missionCompleteReward: null,
    missionCompleteTitle: null,
    examMissionCompletedEvent: {
        score: null,
        mission_title: null,
        total_bits_collected: null,
        first_round_uuid: null,
    },
    unlockedAchievements: null,
    suggestedMissions: [],
    customTask: null,
    shouldResetSearchState: false,
    referralToken: null,
});
