import { defineStore } from 'pinia';
import { useQuestStore } from '../QuestStore';
import { QuestContentTaskTypeEnum, QuestRoundTypeEnum } from '@/js/quests/game/types/enums';
import { setValueToLocalStorage } from '@/js/quests/game/helpers/localStorageHelper';
import { LOCAL_STORAGE_KEYS } from '@/js/quests/game/constants/localStorageKeys';
import { useQuestRoundDataStore } from './QuestRoundDataStore';

export const useQuizHelperStore = defineStore({
    id: 'quizHelper',
    actions: {
        isRoundType(type: QuestRoundTypeEnum): boolean {
            const questRoundDataStore = useQuestRoundDataStore();

            return questRoundDataStore.roundData.roundType === type;
        },
        isQuestTaskType(type: string): boolean {
            const questRoundDataStore = useQuestRoundDataStore();

            return questRoundDataStore.currentQuest?.quest_task_type === type;
        },
        checkIfTaskTypeIsSame(type: number): boolean {
            const questRoundDataStore = useQuestRoundDataStore();

            return questRoundDataStore.currentQuest?.quest_task.type === type;
        },
        populateSortQuestionOptions() {
            const questRoundDataStore = useQuestRoundDataStore();
            const questStore = useQuestStore();

            if (this.isRoundType(QuestRoundTypeEnum.CONTENT_ROUND) && this.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.SORT) && questRoundDataStore.currentQuest.quest_task) {
                questStore.userAnswers.sort = [...questRoundDataStore.currentQuest.quest_task.content_task_options];
            } else {
                questStore.userAnswers.sort = [];
            }
        },
        handleUnload() {
            const questRoundDataStore = useQuestRoundDataStore();
            const questStore = useQuestStore();

            if (questStore.cooldownLeft !== null) {
                setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN, questStore?.cooldownLeft.toString());
            }
            if (questRoundDataStore.correctStreakCount) {
                setValueToLocalStorage(LOCAL_STORAGE_KEYS.CORRECT_STREAK_COUNT, questRoundDataStore.correctStreakCount.toString());
            }
            if (questRoundDataStore.incorrectStreakCount) {
                setValueToLocalStorage(LOCAL_STORAGE_KEYS.INCORRECT_STREAK_COUNT, questRoundDataStore.incorrectStreakCount.toString());
            }
        },
    },
});
