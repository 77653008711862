import { defineStore } from 'pinia';
import { Mission } from '@/js/quests/dashboard/types/types';
import { useQuestState } from './state';

import {
    QuestContentTaskTypeEnum,
    QuestNotificationTypesEnum,
    QuestQuestTaskTypeEnum,
    QuestRoundTypeEnum,
    QuestTaskStatusEnum,
} from '@/js/quests/game/types/enums';

import {
    getValueFromLocalStorage,
    removeQuestValuesFromLocalStorage,
    setValueToLocalStorage,
} from '@/js/quests/game/helpers/localStorageHelper';

import { LOCAL_STORAGE_KEYS } from '@/js/quests/game/constants/localStorageKeys';
import { closeCurtains } from '@/js/quests/game/animations/closeCurtains';

import { useQuizHelperStore } from './modules/QuestHelperStore';
import { useAsyncApiStore } from './modules/AsyncApiStore';
import { useNotificationStore } from './modules/NotificationStore';
import { useQuestRoundDataStore } from './modules/QuestRoundDataStore';
import { QUESTION_CHANGE_ANIMATION_TIME_IN_MS } from '@/js/quests/game/config/animationsConfig';
import { ContentTaskOptions } from '@/js/quests/game/types/types';
import { getCharBasedOnIndex } from '@/js/quests/game/helpers/questTaskHelpers';

export const useQuestStore = defineStore({
    id: 'quest',
    state: () => useQuestState(),
    getters: {
        remainingSeconds(): number | null {
            return this.cooldownLeft;
        },
        featuredMission(): Mission | null {
            return this.suggestedMissions.length > 0 ? this.suggestedMissions[0] : null;
        },
        customTaskBits(): number {
            if (!this.customTask || !this.customTask.bits_reward_amount) {
                return 0;
            }

            return this.customTask.bits_reward_amount as number;
        },
    },
    actions: {
        endRound(timeEnded?: boolean) {
            const asyncApiStore = useAsyncApiStore();

            removeQuestValuesFromLocalStorage();
            closeCurtains();
            setTimeout(() => {
                if (timeEnded) {
                    if (this.userRoundUuid) {
                        asyncApiStore.cancelRoundByTimeEnd();
                    }

                    this.hasTimeEnded = true;
                } else {
                    this.hasGameEnded = true;
                }
            }, 1000);
        },
        nextQuestionCountdown() {
            const questRoundDataStore = useQuestRoundDataStore();

            const localStorageCooldown = getValueFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN);
            const quest = questRoundDataStore.currentQuest;
            this.cooldownLeft = quest ? quest.cooldown : 0;

            if (localStorageCooldown && quest) {
                this.cooldownLeft = parseInt(localStorageCooldown, 10);
            }

            const interval = setInterval(() => {
                if (this.cooldownLeft && this.cooldownLeft > 0) {
                    this.cooldownLeft -= 1;
                } else {
                    this.cooldownLeft = 0;
                    clearInterval(interval);
                }
            }, 1000);
        },
        continueToNextQuestion() {
            const quizHelperStore = useQuizHelperStore();
            const notificationStore = useNotificationStore();
            const questRoundDataStore = useQuestRoundDataStore();

            notificationStore.shouldShowNotification = false;

            if (!quizHelperStore.isQuestTaskType(QuestQuestTaskTypeEnum.VISIT_SITE_TASK)
                && (questRoundDataStore.roundData.roundType === QuestRoundTypeEnum.CONTENT_ROUND
                    || questRoundDataStore.roundData.roundType === QuestRoundTypeEnum.TRACKER_ROUND)) {
                if (this.checkEmptyUserAnswersBasedOnType()) {
                    return;
                }
            }

            setTimeout(() => {
                const previousQuestionUuid = getValueFromLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_ACTION);
                let tempIndex = questRoundDataStore.roundData.currentQuestions.findIndex((x) => x.uuid === previousQuestionUuid);

                setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN, questRoundDataStore.currentQuest?.cooldown.toString());

                this.answerDisabled = [];

                if (tempIndex >= questRoundDataStore.roundData.currentQuestions.length) {
                    if (questRoundDataStore.roundData.incorrectAnswers.length > 0) {
                        tempIndex = 0;

                        questRoundDataStore.roundData.currentQuestions = [...questRoundDataStore.roundData.incorrectAnswers];
                        this.answerDisabled = new Array(questRoundDataStore.roundData.currentQuestions.length).fill(false);

                        // Save the uuid of the first incorrect question to localStorage
                        setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_COOLDOWN, questRoundDataStore.roundData.currentQuestions[0]?.cooldown.toString());
                        setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_ACTION, questRoundDataStore.roundData.currentQuestions[0]?.uuid);
                    }
                    tempIndex = 0;
                }

                // If method hasn't returned already, assign tempIndex to currentQuestionIndex
                questRoundDataStore.currentQuestionIndex = tempIndex;

                this.userAnswers.entry = [];
                this.userAnswers.select = [];
                this.userAnswers.checkbox = [];
                this.userAnswers.sort = [];
                this.userAnswers.tracker = null;
                quizHelperStore.populateSortQuestionOptions();
                this.nextQuestionCountdown();
                this.selectedButtonIndex = null;
                this.setShouldResetSearchState(true);
            }, QUESTION_CHANGE_ANIMATION_TIME_IN_MS); // (Waiting until the notification transition is over to go on the next question)
        },
        removeIncorrectAnswer() {
            const questRoundDataStore = useQuestRoundDataStore();

            const incorrectAnswersIndex = questRoundDataStore.roundData.incorrectAnswers.findIndex(
                (question) => question.uuid === questRoundDataStore.currentQuest?.uuid,
            );

            if (incorrectAnswersIndex !== -1) {
                questRoundDataStore.roundData.incorrectAnswers.splice(incorrectAnswersIndex, 1);
            }
        },
        addIncorrectAnswer() {
            const questRoundDataStore = useQuestRoundDataStore();

            if (
                !questRoundDataStore.roundData.incorrectAnswers.find(
                    (question) => question.uuid === questRoundDataStore.currentQuest?.uuid,
                )
            ) {
                questRoundDataStore.roundData.incorrectAnswers.push(questRoundDataStore.currentQuest);
            }
        },
        findOriginalQuestionIndex() {
            const questRoundDataStore = useQuestRoundDataStore();

            return questRoundDataStore.roundData.originalQuestions.findIndex(
                (question) => question.uuid === questRoundDataStore.currentQuest?.uuid,
            );
        },
        updateQuestionStatuses(status: QuestTaskStatusEnum) {
            const questRoundDataStore = useQuestRoundDataStore();

            const originalPosition = questRoundDataStore.roundData.questions.indexOf(questRoundDataStore.currentQuest);
            const allQuestionsOriginalPosition = this.findOriginalQuestionIndex();

            questRoundDataStore.roundData.questionsStatus[originalPosition] = status;
            questRoundDataStore.roundData.allQuestionsStatus[allQuestionsOriginalPosition] = status;
        },
        formattedCorrectAnswer(correctAnswer: string[]) {
            const questRoundDataStore = useQuestRoundDataStore();

            const emptyArray: ContentTaskOptions[] = [{ title: '', value: '' }];
            const contentTaskOptions = questRoundDataStore.currentQuest.quest_task?.content_task_options || emptyArray;
            const correctValues = contentTaskOptions.filter((action: ContentTaskOptions) => correctAnswer.includes(action.value));

            if (!correctValues.length) {
                return correctAnswer.toString();
            }

            const isContentRound = questRoundDataStore.roundData.roundType === QuestRoundTypeEnum.CONTENT_ROUND;
            const isContentTask = questRoundDataStore.currentQuest.quest_task_type === QuestQuestTaskTypeEnum.CONTENT_TASK;
            const isSelectOrCheckbox = isContentRound && isContentTask ? [
                QuestContentTaskTypeEnum.SELECT,
                QuestContentTaskTypeEnum.CHECKBOX,
            ].includes(questRoundDataStore.currentQuest.quest_task.type as QuestContentTaskTypeEnum) : false;

            const optionMap = new Map(contentTaskOptions.map((option) => [option.value, option]));
            const sortedCorrectAnswer = correctAnswer.map((answerValue) => {
                const matchedOption = optionMap.get(answerValue);
                if (!matchedOption) {
                    return '';
                }

                let prefix = '';
                if (isSelectOrCheckbox) {
                    const index = contentTaskOptions.findIndex((option) => option.value === answerValue);
                    if (index !== -1) {
                        prefix = `${getCharBasedOnIndex(index)}. `;
                    }
                }

                return prefix + matchedOption.title;
            });

            return sortedCorrectAnswer.join(', ');
        },
        validateAnswer(isCorrect: boolean, userHearts: number, correctAnswer = ['']) {
            if (isCorrect) {
                this.processCorrectAnswer();
            } else {
                this.processIncorrectAnswer(userHearts, correctAnswer);
            }
        },
        processCorrectAnswer() {
            const questRoundDataStore = useQuestRoundDataStore();

            const notificationStore = useNotificationStore();

            notificationStore.displayNotification(QuestNotificationTypesEnum.CORRECT);
            this.correctAnswers += 1;

            this.updateQuestionStatuses(QuestTaskStatusEnum.CORRECT);
            this.removeIncorrectAnswer();

            questRoundDataStore.incorrectStreakCount = 0;
            questRoundDataStore.correctStreakCount += 1;
        },
        processIncorrectAnswer(userHearts: number, correctAnswer = ['']) {
            const questRoundDataStore = useQuestRoundDataStore();

            const notificationStore = useNotificationStore();

            notificationStore.displayNotification(QuestNotificationTypesEnum.INCORRECT, this.formattedCorrectAnswer(correctAnswer));

            questRoundDataStore.userProfile.userHearts = userHearts;
            this.addIncorrectAnswer();

            this.updateQuestionStatuses(QuestTaskStatusEnum.INCORRECT);

            questRoundDataStore.correctStreakCount = 0;
            questRoundDataStore.incorrectStreakCount += 1;
        },
        getNextQuestionUUID() {
            const questRoundDataStore = useQuestRoundDataStore();

            let nextQuestionUuid = '';

            const statusesArray = Object.values(questRoundDataStore.roundData.allQuestionsStatus) as QuestTaskStatusEnum[];

            const availableIndexes = statusesArray.reduce((accumulator, status, index) => {
                if (status === QuestTaskStatusEnum.UNANSWERED) {
                    return [...accumulator, index];
                }

                return accumulator;
            }, [] as number[]);

            if (availableIndexes.length === 0) {
                const incorrectAnswersUuids = new Set(questRoundDataStore.roundData.incorrectAnswers.map((answer) => answer.uuid));
                const nextIncorrectQuestion = questRoundDataStore.roundData.currentQuestions
                    .slice(questRoundDataStore.currentQuestionIndex + 1)
                    .find((question) => incorrectAnswersUuids.has(question.uuid));

                // or statement is needed if there are only 1 question and you repeatedly choose wrong answer
                nextQuestionUuid = nextIncorrectQuestion?.uuid || questRoundDataStore.roundData.incorrectAnswers[0]?.uuid || '';
            } else {
                nextQuestionUuid = questRoundDataStore.roundData.originalQuestions[availableIndexes[0]]?.uuid || '';
            }

            return nextQuestionUuid;
        },
        getNextIncorrectQuestionUUID() {
            const questRoundDataStore = useQuestRoundDataStore();

            const nextIncorrectQuestion = questRoundDataStore.roundData.incorrectAnswers[0];

            return nextIncorrectQuestion?.uuid || '';
        },
        async verifySubmission(timestamp?: string) {
            const questRoundDataStore = useQuestRoundDataStore();

            const quizHelperStore = useQuizHelperStore();
            const asyncApiStore = useAsyncApiStore();

            if (quizHelperStore.isQuestTaskType(QuestQuestTaskTypeEnum.VISIT_SITE_TASK)) {
                await asyncApiStore.verifySubmissionAndUpdateRoundState(timestamp);
            } else if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.CHECKBOX)) {
                await asyncApiStore.verifySubmissionAndUpdateRoundState(this.userAnswers.checkbox);
            } else if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.SORT)) {
                await asyncApiStore.verifySubmissionAndUpdateRoundState(this.userAnswers.sort.map((obj) => obj.value));
            } else if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.SELECT)) {
                if (questRoundDataStore.currentQuest?.quest_task_type === QuestQuestTaskTypeEnum.CONTENT_TASK) {
                    await asyncApiStore.verifySubmissionAndUpdateRoundState(this.userAnswers.select || 0);
                } else {
                    await asyncApiStore.verifySubmissionAndUpdateRoundState(this.userAnswers.select);
                }
            } else if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.ENTRY)) {
                if (questRoundDataStore.currentQuest?.quest_task_type === QuestQuestTaskTypeEnum.TRACKER_TASK) {
                    await asyncApiStore.verifySubmissionAndUpdateRoundState(this.userAnswers.tracker || 0);
                } else {
                    const filteredEntries = this.userAnswers.entry
                    .map((value) => value.trim())
                    .filter((value) => value.length > 0);

                    await asyncApiStore.verifySubmissionAndUpdateRoundState(filteredEntries);
                }
            }
        },
        async processAnswerSubmissionType(timestamp?: string) {
            const questRoundDataStore = useQuestRoundDataStore();
            const notificationStore = useNotificationStore();
            const quizHelperStore = useQuizHelperStore();

            if (this.validatingAction || notificationStore.shouldShowNotification || this.remainingSeconds) {
                return;
            }

            if (!quizHelperStore.isQuestTaskType(QuestQuestTaskTypeEnum.VISIT_SITE_TASK) && this.checkEmptyUserAnswersBasedOnType()) {
                notificationStore.displayNotification(QuestNotificationTypesEnum.NOT_SELECTED);

                return;
            }

            this.validatingAction = true;

            let nextQuestionUuid = '';
            const thereAreRemainingIncorrect = questRoundDataStore.roundData.incorrectAnswers.length > 0;
            const statusesArray = Object.values(questRoundDataStore.roundData.allQuestionsStatus) as QuestTaskStatusEnum[];

            const areUnansweredQuestions = statusesArray.some((status) => status === QuestTaskStatusEnum.UNANSWERED);
            const currentIsLastQuestion = (questRoundDataStore.currentQuestionIndex + 1) === questRoundDataStore.roundData.currentQuestions.length && !areUnansweredQuestions;

            await this.verifySubmission(timestamp);

            if (currentIsLastQuestion && thereAreRemainingIncorrect) {
                nextQuestionUuid = this.getNextIncorrectQuestionUUID();
            } else {
                nextQuestionUuid = this.getNextQuestionUUID();
            }

            setValueToLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_QUEST_ACTION, nextQuestionUuid);
        },
        checkEmptyUserAnswersBasedOnType() {
            const questRoundDataStore = useQuestRoundDataStore();
            const quizHelperStore = useQuizHelperStore();

            if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.SELECT) && this.userAnswers.select.length === 0) {
                return true;
            }

            if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.ENTRY)) {
                if (questRoundDataStore.currentQuest?.quest_task_type === QuestQuestTaskTypeEnum.TRACKER_TASK && this.userAnswers.tracker === null) {
                    return true;
                }

                if (questRoundDataStore.currentQuest?.quest_task_type !== QuestQuestTaskTypeEnum.TRACKER_TASK && this.userAnswers.entry.length === 0) {
                    return true;
                }
            }

            if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.CHECKBOX) && this.userAnswers.checkbox.length === 0) {
                return true;
            }

            if (quizHelperStore.checkIfTaskTypeIsSame(QuestContentTaskTypeEnum.CHECKBOX) && this.userAnswers.checkbox.length === 0) {
                return true;
            }

            return false;
        },
        setShouldResetSearchState(value: boolean) {
            this.shouldResetSearchState = value;
        },
    },
});
