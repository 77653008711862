export enum QuestNotificationTypesEnum {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
    NOT_SELECTED = 'not-selected',
    TASK_COMPLETED = 'task-completed',
    TASK_NOT_COMPLETED = 'task-not-completed'
}

export enum QuestActionValidationResultEnum {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
}

export enum QuestSoundTypesEnum {
    SUCCESS = 'https://images.bitdegree.org/assets/missions/sounds/success-sound.mp3',
    FAILED = 'https://images.bitdegree.org/assets/missions/sounds/fail-sound.mp3',
    COUNTDOWN1 = 'https://images.bitdegree.org/assets/missions/sounds/tick-tack1.mp3',
    COUNTDOWN2 = 'https://images.bitdegree.org/assets/missions/sounds/tick-tack2.mp3',
    COUNTDOWN3 = 'https://images.bitdegree.org/assets/missions/sounds/tick-tack3.mp3',
    COUNTDOWN4 = 'https://images.bitdegree.org/assets/missions/sounds/tick-tack4.mp3',
    COUNTDOWN5 = 'https://images.bitdegree.org/assets/missions/sounds/tick-tack5.mp3',
    COMPLETION = 'https://images.bitdegree.org/assets/missions/sounds/trumpets-winning.mp3',
    GAIN = 'https://images.bitdegree.org/assets/missions/sounds/gain-coins.mp3',
    WHOSH = 'https://images.bitdegree.org/assets/missions/sounds/motivation-whosh.mp3',
    TIMEOUT = 'https://images.bitdegree.org/assets/missions/sounds/timeout-sound-effect.mp3'
}

export enum QuestQuestTaskTypeEnum {
    CONTENT_TASK = 'quest_content_task',
    TRACKER_TASK = 'quest_tracker_task',
    VISIT_SITE_TASK = 'quest_visit_site_task',
}

export enum QuestVisitSiteTaskTypeEnum {
    WEBSITE = 1,
    VIDEO = 2,
}

export enum QuestIntroductionStepsEnum {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

export enum QuestContentTaskTypeEnum {
    ENTRY = 1,
    SELECT = 2,
    CHECKBOX = 3,
    SORT = 4
}

export enum QuestSocialNetworkEnum {
    DISCORD = 1,
    TELEGRAM = 2,
    TWITTER = 3,
}

export enum QuestSocialTaskTypeEnum {
    MEMBER = 1,
    ROLE = 2,
    SHARE = 3,
    POST = 4,
    COMMENT = 5,
    LIKE = 6,
    FOLLOW = 7,
}

export enum QuestTaskStatusEnum {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
    UNANSWERED = 'unanswered'
}

export enum QuestRoundTypeEnum {
    CONTENT_ROUND = 1,
    SOCIAL_ROUND = 2,
    BLOCKCHAIN_ROUND = 3,
    TRACKER_ROUND = 4,
}

export enum QuestEventTypeEnum {
    ROUND_COMPLETE = 'round_complete',
    MISSION_COMPLETE = 'mission_complete',
    MISSION_SUGGESTION = 'mission_suggestion',
    DEGREE_CHANGED = 'degree_changed',
    ROUND_STRIKE = 'round_strike',
    HEART_FEATURE_ENABLED = 'heart_feature_enabled',
    ACHIEVEMENTS_UNLOCKED = 'achievements_unlocked',
    HEART_LOST = 'heart_lost',
    QUEST_STREAK_INCREMENTED = 'quest_streak_incremented',
    QUEST_STREAK_INCREMENT_REWARDED = 'quest_streak_increment_rewarded',
    QUEST_STREAK_FREEZE_INTRODUCTION = 'quest_streak_freeze_introduction',
    QUEST_STREAK_FREEZE_POINTS_RESTORED = 'quest_streak_freeze_points_restored',
    QUEST_STREAK_REWARD_CALCULATION = 'quest_streak_reward_calculation',
    EXAM_COMPLETED = 'exam_completed',
    NFT_CERTIFICATE_CREATED = 'nft_certificate_created',
    CUSTOM_TASK_SUGGESTION = 'custom_task_suggestion',
}

export enum QuestMotivationEventEnum {
    RETRY_TIME = 'retry_time',
    THREE_IN_A_ROW_CORRECT = 'three_in_a_row_correct',
    FIVE_IN_A_ROW_CORRECT = 'five_in_a_row_correct',
    THREE_IN_A_ROW_INCORRECT = 'three_in_a_row_incorrect',
    HEART_LOST = 'heart_lost',
    ALL_HEARTS_LOST = 'all_hearts_lost',
}

export enum QuestTrackerTaskAnswerMagnitude {
    K = 3,
    M = 6,
    B = 9
}

export enum QuestTrackerTaskValueTypeEnum {
    CURRENT_VALUE = 1,
    CHANGE_VALUE = 3,
    CHANGE_DIRECTION = 4,
}

export enum QuestContentTaskInputTypeEnum {
    TEXT = 1,
    NUMERIC = 2,
}
export enum EndScreenActionAltButtonTypeEnum {
    INTRO = 'intro',
    INCORRECT = 'incorrect',
    DYNAMIC = 'dynamic',
}

export enum ArticleSectionIdEnum {
    TUTORIAL = 'tutorial-article-content-section',
    REVIEW = 'review-article-content-section',
    CATEGORY = 'category-article-content-section',
}

export enum CustomTaskSuggestionTypeEnum {
    RATING = 'rating',
    REFERRAL = 'referral',
    VISIT_SITE = 'visit_site',
    WATCH_VIDEO = 'watch_video',
    DOWNLOAD_APP = 'download_app',
}

export enum CustomTaskSuggestionScreensEnum {
    TASK = 'task',
    CONGRATULATIONS = 'congratulations',
}
