export interface Web3ErrorMessages {
    RESOURCE_NOT_AVAILABLE: string;
    ALREADY_PROCESSING: string;
    USER_DENIED_MESSAGE_SIGNATURE: string;
    USER_REJECTED_THE_REQUEST: string;
}

export const defaultWeb3ErrorMessages: Web3ErrorMessages = {
    RESOURCE_NOT_AVAILABLE: 'Requested resource not available',
    ALREADY_PROCESSING: 'Already processing eth_requestAccounts',
    USER_DENIED_MESSAGE_SIGNATURE: 'User denied message signature',
    USER_REJECTED_THE_REQUEST: 'User rejected the request.',
};
