import { RouteRecordName } from 'vue-router';

interface DashboardRoutesNames {
    MISSIONS_ROUTE_NAME: RouteRecordName;
    MISSION_ROUTE_NAME: RouteRecordName;
    MISSION_PREVIEW_ROUTE_NAME: RouteRecordName;
    GUILDS_ROUTE_NAME: RouteRecordName;
    GUILDS_STUDIO_ROUTE_NAME: RouteRecordName;
    LEADERBOARD_ROUTE_NAME: RouteRecordName;
    FRIENDS_ROUTE_NAME: RouteRecordName;
    WALLET_ROUTE_NAME: RouteRecordName;
    ENGAGE_ROUTE_NAME: RouteRecordName;
    SETTINGS_ROUTE_NAME: RouteRecordName;
    NFT_CERTIFICATE_VERIFICATION_ROUTE_NAME: RouteRecordName;
    GUILD_ROUTE_NAME: RouteRecordName;
    BONUS_ROUTE_NAME: RouteRecordName;
}

export const dashboardRoutesNames: DashboardRoutesNames = {
    MISSIONS_ROUTE_NAME: 'missions',
    MISSION_ROUTE_NAME: 'mission',
    MISSION_PREVIEW_ROUTE_NAME: 'mission-preview',
    GUILDS_ROUTE_NAME: 'guilds',
    GUILDS_STUDIO_ROUTE_NAME: 'guilds-studio',
    LEADERBOARD_ROUTE_NAME: 'leaderboard',
    FRIENDS_ROUTE_NAME: 'friends',
    WALLET_ROUTE_NAME: 'wallet',
    ENGAGE_ROUTE_NAME: 'engage',
    SETTINGS_ROUTE_NAME: 'settings',
    NFT_CERTIFICATE_VERIFICATION_ROUTE_NAME: 'nft-certificate-verification',
    GUILD_ROUTE_NAME: 'guild',
    BONUS_ROUTE_NAME: 'bonus',
};
