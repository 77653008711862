import { createRouter, createWebHistory } from 'vue-router';
import { setMetaTag, setOpenGraphMetaTags, setTwitterMetaTags } from './helpers/metaHelper';
import { dashboardRoutesNames } from '../quests/dashboard/constants/dashboardRoutesNames';
import { getTrans } from '../helpers/getTrans';
import { dashboardRoutesPaths } from '../quests/dashboard/constants/dashboardRoutesPaths';

const routes = [
    {
        path: dashboardRoutesPaths.MISSIONS_ROUTE_PATH,
        name: dashboardRoutesNames.MISSIONS_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Missions.vue'),
        meta: {
            title: getTrans('meta.missionsMetaTitle', 'BitDegree Missions - Learn Web3, Complete Rounds & Earn Rewards'),
            description: getTrans('meta.missionsMetaDescription', 'Learn Web3 by doing: embark on the BitDegree adventure of completing Web3 Missions, gaining Bits, and earning special crypto rewards!'),
        },
    },
    {
        path: dashboardRoutesPaths.GUILDS_ROUTE_PATH,
        name: dashboardRoutesNames.GUILDS_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/guilds/Guilds.vue'),
        meta: {
            title: getTrans('meta.guildsMetaTitle', 'Join Your Favorite Communities: Get Airdrops & Crypto Rewards'),
            description: getTrans('meta.guildsMetaDescription', 'Claim your spot in exclusive crypto airdrops & get rewards from Top brands. Join Guilds - become a part of the most influential communities!'),
        },
    },
    {
        path: dashboardRoutesPaths.GUILDS_STUDIO_ROUTE_PATH,
        name: dashboardRoutesNames.GUILDS_STUDIO_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/guilds-studio/GuildsStudio.vue'),
        meta: {
            title: getTrans('meta.studioMetaTitle', 'Guilds Studio: Manage Guilds, Edit Missions & Track Stats'),
            description: getTrans('meta.studioMetaDescription', 'Create, edit, and manage your Guilds & Missions easily with Guilds Studio. Get all the stats and learn how to engage your community better!'),
        },
    },
    // Here meta tags handling is done within the component since title and description come from the DB
    {
        path: dashboardRoutesPaths.MISSION_ROUTE_PATH,
        name: dashboardRoutesNames.MISSION_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Mission.vue'),
    },
    {
        path: dashboardRoutesPaths.MISSION_PREVIEW_ROUTE_PATH,
        name: dashboardRoutesNames.MISSION_PREVIEW_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Mission.vue'),
        props: {
            isPreview: true,
        },
    },
    {
        path: dashboardRoutesPaths.LEADERBOARD_ROUTE_PATH,
        name: dashboardRoutesNames.LEADERBOARD_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Leaderboard.vue'),
        meta: {
            title: getTrans('meta.leaderboardMetaTitle', 'Join the Season 7 Crypto Airdrop & Share the $30,000 Prize'),
            description: getTrans('meta.leaderboardMetaDescription', 'The Season 7 Crypto Airdrop is now open. Complete Missions, refer friends, collect Bits & get a stake of the $30,000 crypto Prize Pool!'),
        },
    },
    {
        path: dashboardRoutesPaths.FRIENDS_ROUTE_PATH,
        name: dashboardRoutesNames.FRIENDS_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Friends.vue'),
        meta: {
            title: getTrans('meta.friendsMetaTitle', 'Invite Friends and Earn Rewards – Start Referring Today'),
            description: getTrans('meta.friendsMetaDescription', 'Invite your friends to join BitDegree and earn rewards for every referral. Start sharing and unlock bonuses when your friends sign up!'),
        },
    },
    {
        path: dashboardRoutesPaths.WALLET_ROUTE_PATH,
        name: dashboardRoutesNames.WALLET_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Wallet.vue'),
        meta: {
            title: getTrans('meta.walletMetaTitle', 'Track Your Earnings & Rewards'),
            description: getTrans('meta.walletMetaDescription', 'Check all your Bitdegree earnings, rewards and achievements in one place. Complete Missions & maximize your gains!'),
        },
    },
    {
        path: dashboardRoutesPaths.ENGAGE_ROUTE_PATH,
        name: dashboardRoutesNames.ENGAGE_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/CreateMission.vue'),
        meta: {
            title: getTrans('meta.engageMetaTitle', 'Create Guilds & Engage Communities With the Mission Builder'),
            description: getTrans('meta.engageMetaDescription', 'Take your community out of the dark. Grow, engage & reward your fanbase by accessing the BitDegree Mission Builder.'),
        },
    },
    {
        path: dashboardRoutesPaths.SETTINGS_ROUTE_PATH,
        name: dashboardRoutesNames.SETTINGS_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Settings.vue'),
        meta: {
            title: getTrans('meta.settingsMetaTitle', 'BitDegree Web3 Missions Settings: Manage Your Profile Details'),
            description: getTrans('meta.settingsMetaDescription', 'Easily adjust & manage your personal details on the BitDegree Web3 Missions settings page.'),
        },
    },
    {
        path: dashboardRoutesPaths.NFT_CERTIFICATE_VERIFICATION_ROUTE_PATH,
        name: dashboardRoutesNames.NFT_CERTIFICATE_VERIFICATION_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/NftCertificateVerification.vue'),
        meta: {
            title: getTrans('meta.nft-certificate-verificationMetaTitle', 'NFT Certificate Verification: Check Certificate\'s Validity'),
            description: getTrans('meta.nft-certificate-verificationMetaDescription', 'Use BitDegree NFT Certificate Verification Tool to quickly check your NFT Certificate\'s authenticity!'),
        },
    },
    {
        path: dashboardRoutesPaths.BONUS_ROUTE_PATH,
        name: dashboardRoutesNames.BONUS_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/Bonus.vue'),
        meta: {
            title: getTrans('meta.bonusMetaTitle', 'Quick Tasks for Extra Bits – Start Earning Now'),
            description: getTrans('meta.bonusMetaDescription', 'Complete simple tasks in seconds and claim extra Bits instantly. Fast, easy, and rewarding – start stacking your Bits now!'),
        },
    },
    {
        path: dashboardRoutesPaths.GUILD_ROUTE_PATH,
        name: dashboardRoutesNames.GUILD_ROUTE_NAME,
        component: () => import('../quests/dashboard/pages/guild/Guild.vue'),
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('../quests/dashboard/pages/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach((to, from, next) => {
    // Skip handling meta tags for routes where meta handling is done within the component
    if (to.name === dashboardRoutesNames.MISSION_ROUTE_NAME && to.name === dashboardRoutesNames.GUILD_ROUTE_NAME) {
        next();

        return;
    }

    const title = typeof to.meta.title === 'function'
        ? to.meta.title(to)
        : to.meta.title || getTrans('quests-dashboard.bitdegreeMission', 'BitDegree Missions - Learn Web3, Complete Rounds & Earn Rewards');
    const description = typeof to.meta.description === 'function'
        ? to.meta.description(to)
        : to.meta.description || getTrans('quests-dashboard.learnWeb3', 'Learn Web3 by doing: embark on the BitDegree adventure of completing Web3 Missions, gaining Bits, and earning special crypto rewards!');
    const url = window.location.origin + to.fullPath;

    document.title = title;

    setOpenGraphMetaTags(title, description, url);
    setTwitterMetaTags(title, description);
    setMetaTag('meta[name="description"]', 'content', description);

    next();
});

router.onError((to) => {
    let url;

    if (to.href) {
        url = to.href;
    } else if (to.fullPath) {
        url = window.location.origin + to.fullPath;
    }

    if (url) {
        window.location.href = url;
    } else {
        window.location.reload();
    }
});

export default router;
