export interface TimeConstantsInMs {
    ONE_HOUR: {
        value: number;
    };
    ONE_DAY: {
        value: number;
    };
    ONE_WEEK: {
        value: number;
    };
}

export const TIME_CONSTANTS_IN_MS: TimeConstantsInMs = {
    ONE_HOUR: {
        value: 3600000,
    },
    ONE_DAY: {
        value: 86400000,
    },
    ONE_WEEK: {
        value: 604800000,
    },
};
