import { AxiosError } from 'axios';
import { questsRoutes } from '@/js/constants/questsRoutes';
import { HTTP_STATUS } from '@/js/enums/httpStatus';
import { closeCurtains } from '../animations/closeCurtains';
import { useAsyncApiStore } from '@/js/store/quests/game/modules/AsyncApiStore';
import { useQuestStore } from '@/js/store/quests/game/QuestStore';
import { setValueToLocalStorage } from '../helpers/localStorageHelper';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { messages } from '../constants/messages';
import { showToast } from '@/js/helpers/toastHelpers';
import { ApiErrorResponse } from '@/js/types/types';
import { setCookie } from '@/js/helpers/cookieHelpers';
import { COOKIES_KEYS } from '@/js/quests/game/constants/cookiesKeys';

export const handleHttpError = (error: AxiosError<ApiErrorResponse>): void => {
        if (!error) {
            return;
        }

        const status = error?.response?.status || HTTP_STATUS.INTERNAL_SERVER_ERROR;
        const errorMessage = error?.response?.data?.message;

        const { terminateRoundDueToTechnicalIssue } = useAsyncApiStore();
        const { endRound } = useQuestStore();

        const redirectToMissions = (): void => {
            closeCurtains(questsRoutes.MISSIONS_ROUTE);
        };

        const showToastMessage = (message: string) => {
            showToast({
                message,
                type: 'error',
                position: 'top-right',
                duration: 4000,
            });
        };

        const handleLockedError = (): void => {
            setValueToLocalStorage(LOCAL_STORAGE_KEYS.LOCKED_ROUND, 'true');
            redirectToMissions();
        };

        const handleServerError = (): void => {
            terminateRoundDueToTechnicalIssue();
        };

        const handleUnprocessableEntityError = (): void => {
            const message = errorMessage || messages.FALLBACK_MESSAGE;

            showToastMessage(message);
        };

        const handleToManyRequestsError = (): void => {
            const message = errorMessage || messages.TOO_MANY_ATTEMPTS;

            showToastMessage(message);
        };

        const handleUnauthorizedError = (): void => {
            const message = {
                subtitle: messages.MISSION_COMPLETION,
                buttonText: messages.GOT_IT,
            };

            setCookie(COOKIES_KEYS.CUSTOM_ERROR_MODAL, JSON.stringify(message));
            redirectToMissions();
        };

        const handleConflictError = (): void => {
            const message = {
                subtitle: messages.MISSION_COMPLETION,
                buttonText: messages.GOT_IT,
            };

            setCookie(COOKIES_KEYS.CUSTOM_ERROR_MODAL, JSON.stringify(message));
            redirectToMissions();
        };

        const handleForbiddenError = (): void => {
            const message = {
                subtitle: messages.MISSION_COMPLETION,
                buttonText: messages.GOT_IT,
            };

            setCookie(COOKIES_KEYS.CUSTOM_ERROR_MODAL, JSON.stringify(message));
            redirectToMissions();
        };

        switch (status) {
            case HTTP_STATUS.CONFLICT:
                handleConflictError();
                break;
            case HTTP_STATUS.LOCKED:
                handleLockedError();
                break;
            case HTTP_STATUS.INTERNAL_SERVER_ERROR:
                handleServerError();
                break;
            case HTTP_STATUS.GONE:
                endRound(true);
                break;
            case HTTP_STATUS.TOO_MANY_REQUESTS:
                handleToManyRequestsError();
                break;
            case HTTP_STATUS.FORBIDDEN:
                handleForbiddenError();
                break;
            case HTTP_STATUS.UNAUTHORIZED:
                handleUnauthorizedError();
                break;
            case HTTP_STATUS.UNPROCESSABLE_ENTITY:
                handleUnprocessableEntityError();
                break;
            default:
                handleServerError();
            }
};
