import { getTrans } from '@/js/helpers/getTrans';

interface ErrorDefaultMessages {
    CANCEL_ROUND_BY_USER: string;
    FETCH_ACTIVE_USER_ROUND_DATA: string;
}

export const errorDefaultMessages: ErrorDefaultMessages = {
    CANCEL_ROUND_BY_USER: getTrans('quests-dashboard.failedRoundCancel', 'Failed to cancel round.'),
    FETCH_ACTIVE_USER_ROUND_DATA: getTrans('quests-dashboard.failedFetchUserData', 'Failed to fetch active user round data.'),
};
